<mat-card appearance="outlined" class="month-picker-container">
  <div class="yearSelector">
    <button #leftButton
            (click)="addToYear(-1)"
            class="arrow-left"
            mat-mini-fab>
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <div class="yearDisplay">{{ this.selectedYear }}</div>
    <button #rightButton
            (click)="addToYear(1)"
            class="arrow-right"
            mat-mini-fab>
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>
  <div class="month-container">
    <div (click)="pickMonth(i)"
         *ngFor="let month of monthNames; index as i"
         [class.from]="isFromDate(i)"
         [class.in-range]="isBetweenFromAndTo(i)"
         [class.only]="isOnlyDate(i)"
         [class.to]="isToDate(i)"
         class="month">
      {{ month }}
    </div>
  </div>
  <div class="submit-container">
    <button (click)="cancel()" class="style-font-position" mat-button>Abbrechen</button>
    <button
      *ngIf="showSelectAllButton"
      (click)="clear()"
      mat-stroked-button
      class="style-font-position secondary-button"
      color="primary"
    >
      Alle Monate
    </button>
    <button (click)="submit()"
            [disabled]="shouldDisableSubmitButton()"
            [class.disabled]="shouldDisableSubmitButton()"
            class="style-font-position"
            color="primary"
            mat-flat-button
    >
      Auswählen
    </button>
  </div>
</mat-card>

