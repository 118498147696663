import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {Calendar, CalendarCreator, DayEntry} from '../../util/calendar-creator';
import {DateRangePicker} from '../../util/date-range-picker';
import {DateUtils} from '../../util/date-utils';
import {SubmitMonthService} from '../../services/repository/submit-month.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  public calendar: Calendar;
  @Output() selectedDate = new EventEmitter<Date>();
  @Input() currentMonthObservable: Observable<Date>;
  @Input() dateRangePicker: DateRangePicker;
  @Input() ignoreConstraints = false;
  @Input() possibleToSelectDateInPast = true;

  weekDaysInitialCharacter: string[] = ['M', 'D', 'M', 'D', 'F', 'S', 'S'];
  private currentDate: Date = null;

  constructor(private submitMonthService: SubmitMonthService) {
  }

  ngOnInit(): void {
    this.currentMonthObservable.subscribe(date => {
      this.currentDate = date;
      this.updateCalendar();
    });
  }

  isInDateRange(entry: DayEntry): boolean {
    const from = this.dateRangePicker.from;
    const to = this.dateRangePicker.to;
    return DateUtils.isBetweenOrSameDate(entry.dateObject, from, to);
  }

  isWeekendDay(entry: DayEntry): boolean {
    return DateUtils.isWeekendDay(entry.dateObject);
  }

  isStartOfDateRange(entry: DayEntry): boolean {
    const from = this.dateRangePicker.from;
    return (from !== undefined) && DateUtils.isSameDate(from, entry.dateObject);
  }

  isEndOfDateRange(entry: DayEntry): boolean {
    const to = this.dateRangePicker.to;
    if (to !== undefined) {
      return DateUtils.isSameDate(to, entry.dateObject);
    } else {
      return this.isStartOfDateRange(entry);
    }
  }

  isDateInMonthSubmitted(entry: DayEntry): boolean {
    if (this.ignoreConstraints) {
      return false;
    }
    return this.submitMonthService.checkIfDateIsInCompletedMonthSubmitted(entry.dateObject);
  }

  isDateInPast(date: Date): boolean {
    const today = DateUtils.today;
    const previousMonth = DateUtils.getPreviousMonth(today);
    return DateUtils.isSameDate(date, previousMonth) || DateUtils.isAfterDate(date, previousMonth);
  }

  selectedDateChanged(selected: DayEntry): void {
    if (!this.possibleToSelectDateInPast) {
      if (this.isDateInPast(selected.dateObject)) {
        this.selectedDate.emit(selected.dateObject);
      }
    }
    if (!this.isDateInMonthSubmitted(selected) && this.possibleToSelectDateInPast) {
      this.selectedDate.emit(selected.dateObject);
    }
  }

  updateCalendar(): void {
    this.calendar = new CalendarCreator().createCalendar(this.currentDate);
  }


}





