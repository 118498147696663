<div>
  <div class="full-width clear-input">
    <mat-form-field class="search" appearance="outline" color="primary">
      <mat-label>{{placeholderText}}</mat-label>
      <input type="text"
             matInput
             class="autocomplete"
             placeholder="{{placeholderText}}"
             [formControl]="userAutocompleteControl"
             [matAutocomplete]="userAutoComplete"
             (ngModelChange)="emitUser($event)"
      >
        <button
          type="button"
          matSuffix
          mat-mini-fab
          *ngIf="userAutocompleteControl.value"
          (click)="onClear()"
          class="position-button">
          <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <mat-autocomplete #userAutoComplete="matAutocomplete" [displayWith]="displayUserEntry">
      <mat-option *ngIf="(filteredUserOptions)?.length === 0">
        <div class="row">
          Leider konnten wir keinen Benutzer finden.
        </div>
      </mat-option>
      <mat-option *ngFor="let user of filteredUserOptions" [value]="user">
        <div class="row">
          {{user.name}}
        </div>
      </mat-option>
    </mat-autocomplete>
  </div>
</div>

