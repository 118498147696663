import {Injectable} from '@angular/core'
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs'
import {GraphqlCollectorService} from '../http/graphql-collector.service'
import {SessionService} from '../session.service'
import {GraphQLQuery} from '../../util/graphql-executor'

export type SqlType = SimpleSqlType | ComplexSqlType

@Injectable({
  providedIn: 'root'
})
export class RemoteConfigurationService {

  // private static readonly tablesToFetch = ["TimeEntries", "AbsenceEntries"]

  private readonly _tableSpecs$ = new BehaviorSubject<TableSpec[]>(undefined)
  private readonly _applicationInsightsInstrumentationKey$ = new ReplaySubject<string>(1)

  constructor(private graphqlService: GraphqlCollectorService,
              private sessionService: SessionService) {
    sessionService.loginData$.subscribe(loginData => {
      if (loginData.isValid) {
        this.fetchTableSpecs()
        this.fetchapplicationInsights()
      }
    })
  }

  get tableSpecs$(): Observable<TableSpec[]> {
    return this._tableSpecs$
  }

  get applicationInsightInstrumentationKey$(): Observable<string> {
    return this._applicationInsightsInstrumentationKey$
  }

  private static tableSpecs(): GraphQLQuery {
    return {
      function: 'tableSpecs',
      variables: [],
      fieldBody: TABLE_SPEC_TEMPLATE
    }
  }

  private static applicationInsights(): GraphQLQuery {
    return {
      function: 'applicationInsights',
      variables: [],
      fieldBody: APPLICATION_INSIGHTS_TEMPLATE
    }
  }

  public fetchapplicationInsights() {
    this.graphqlService.query(RemoteConfigurationService.applicationInsights()).subscribe((response: ApplicationInsights) => {
      this._applicationInsightsInstrumentationKey$.next(response.instrumentationKey)
    })
  }

  private fetchTableSpecs() {
    this.graphqlService.query(RemoteConfigurationService.tableSpecs()).subscribe((response: InternalTableSpec[]) => {
      const tableSpecs = response.map((tableSpec) => {
        return {
          name: tableSpec.tableName,
          columns: this.getAsColumnMap(tableSpec.columns)
        } as TableSpec
      })

      this._tableSpecs$.next(tableSpecs)
    })
  }

  private getAsColumnMap(columns: string[]): Map<string, SqlType> {
    return new Map<string, SqlType>(columns.map(this.extractTableColumnInformation))
  }

  private extractTableColumnInformation(column: string): [string, SqlType] {
    const columnInformation = column.split('=')
    const columnTypeInformation = columnInformation[1].split(',')

    const maxLength = (columnTypeInformation.length > 1) ? +columnTypeInformation[1] : undefined

    const sqlType: SqlType = (maxLength != undefined) ? {
      name: columnTypeInformation[0],
      maxLength: maxLength
    } as ComplexSqlType : {
      name: columnInformation[1]
    } as SimpleSqlType

    return [columnInformation[0], sqlType]
  }
}

interface ApplicationInsights {
  instrumentationKey: string
}

export interface InternalTableSpec {
  tableName: string
  columns: string[]
}

export interface TableSpec {
  name: string
  columns: Map<string, SqlType>
}

interface InternalSqlType {
  name: string
}

export interface SimpleSqlType extends InternalSqlType {
}

export interface ComplexSqlType extends InternalSqlType {
  maxLength: number
}

export function isSimpleSqlType(sqlType: SqlType): sqlType is SimpleSqlType {
  return (sqlType as ComplexSqlType).maxLength === undefined  // Here should be another condition in case there will be another SqlType defined later
}

export function isComplexSqlType(sqlType: SqlType): sqlType is ComplexSqlType {
  return (sqlType as ComplexSqlType).maxLength !== undefined  // not !isSimpleSqlType(sqlType) in case there will be another SqlType defined later
}

export const TABLE_SPEC_TEMPLATE =
  `
    tableName,
    columns
   `

export const APPLICATION_INSIGHTS_TEMPLATE =
  `
   instrumentationKey,
   `
