<button (click)="click()" [class.is-loading]="isLoading" class="timebox-icon-button" [class.is-disabled]="disabled">
  <div class="icon-container">
    <ng-container *ngTemplateOutlet="isLoading ? loading : defaultIcon"></ng-container>
  </div>
  <span class="text-container">{{text}}</span>
</button>

<ng-template #loading>
  <mat-spinner [diameter]="22" class="progress-spinner white-stroked" color="accent"></mat-spinner>
</ng-template>

<ng-template #defaultIcon>
  <mat-icon>{{icon}}</mat-icon>
</ng-template>
