<div class="flex-message-box">
  <div class="flex-message-box-title">{{title}}</div>
  <div class="flex-message-box-content">
    <div class="icon-container" style="padding-right: 16px">
      <mat-icon class="material-icons confirm-icon"
                [style.color]="currentTypeData.color">{{currentTypeData.icon}}</mat-icon>
    </div>
    <div>
      <strong>{{text}}</strong>
    </div>
  </div>
  <div class="button__container">
    <div class="abort-button">
      <button [mat-dialog-close]="false" mat-button>Abbrechen</button>
    </div>
    <div class="submit-button">
      <button [mat-dialog-close]="true" color="primary" mat-flat-button>Ok</button>
    </div>
  </div>
</div>
