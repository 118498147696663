export function extractMutationErrors<T extends MutationError>(payload: any[]): T[] {
  return payload
    ? payload
      .filter(
        element => isMutationError<T>(element)
      )
    : [];
}

export function splitMutationResult<V, E extends MutationError>(arr: any[]): { objects: V[], errors: E[] } {
  const objects: V[] = [];
  const errors: E[] = [];

  arr.forEach(elem => {
    const valueOrError = elem.valueOrError;
    if (isMutationError<E>(valueOrError)) {
      errors.push(valueOrError);
    } else {
      objects.push(valueOrError);
    }
  });

  return {
    objects,
    errors
  };
}

export function isMutationError<T extends MutationError>(payload): payload is T {
  return payload.status !== undefined;
}

export enum ModificationResult {
  SUCCESS,
  WARNING,
  ERROR
}

export interface MutationResult<T extends MutationError> {
  result: ModificationResult;
  errors: T[];
}

export interface MutationError {
  status: number;
}

/* ########################################### ABSENCE ENTRIES ########################################## */
export type AbsenceEntryMutationResult = MutationResult<AbsenceEntryMutationError>;

export interface AbsenceEntryMutationError extends MutationError {
  id: string;
}

export type AbsenceEntryUpsertPayload = AbsenceEntryUpsertResult | AbsenceEntryMutationError;

export interface AbsenceEntryUpsertBulkPayload {
  result: AbsenceEntryUpsertPayload[];
}

export type AbsenceEntryDeletePayload = AbsenceEntryDeleteResult | AbsenceEntryMutationError;

export interface AbsenceEntryDeleteBulkPayload {
  result: AbsenceEntryDeletePayload[];
}

export interface AbsenceEntryUpsertResult {
  id: number;
  monthSubmittedId?: number;
  month: string;
}

export interface AbsenceEntryDeleteResult {
  id: string;
}


/* ############################################ TIME ENTRIES ############################################ */
export type TimeEntryMutationResult = MutationResult<TimeEntryMutationError>;

export interface TimeEntryMutationError extends MutationError {
  id: string;
}

export type TimeEntryUpsertPayload = TimeEntryUpsertResult | TimeEntryMutationError;

export interface TimeEntryUpsertBulkPayload {
  result: TimeEntryUpsertPayload[];
}

export type TimeEntryDeletePayload = TimeEntryDeleteResult | TimeEntryMutationError;

export interface TimeEntryDeleteBulkPayload {
  result: TimeEntryDeletePayload[];
}

export interface TimeEntryUpsertResult {
  id: number;
  monthSubmittedId?: number;
  month: string;
}

export interface TimeEntryDeleteResult {
  id: string;
}
