import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {HttpHeaders} from "@angular/common/http";
import {DateUtils} from "../util/date-utils";

@Injectable({
  providedIn: 'root'
})
export class FetchedMonthsubmittedDataService {

  constructor() {
  }

  private _fetchedMonthSubmitted$ = new Subject<FetchedMonthSubmittedData>()

  get fetchedMonthSubmitted$(): Observable<FetchedMonthSubmittedData> {
    return this._fetchedMonthSubmitted$
  }

  public delegateFetchedMonthSubmittedData(fetchedMonthSubmittedData: FetchedMonthSubmittedData) {
    if (fetchedMonthSubmittedData != null) {
      this._fetchedMonthSubmitted$.next(fetchedMonthSubmittedData)
    }
  }

  public evaluateResponseHeaders(headers: HttpHeaders, forDate: string = null) {
    const monthSubmittedId = headers.get('x-tb-monthsubmitted-id')
    if (monthSubmittedId != undefined) {
      const firstOfMonthString =
        (forDate == null)
          ? ''
          : DateUtils.dateToString(DateUtils.getFirstDayOfMonth(DateUtils.stringToDate(forDate)))

      const fetchedMonthSubmittedData = {
        monthId: +monthSubmittedId,
        month: firstOfMonthString
      } as FetchedMonthSubmittedData
      this._fetchedMonthSubmitted$.next(fetchedMonthSubmittedData)
    }
  }
}

export interface FetchedMonthSubmittedData {
  monthId: number
  month: string
}
