<div class="dialog-container">
  <div class="bubble-box" [class.error]="error">
    <ng-content></ng-content>
  </div>
  <div [ngClass]="{'arrow-left':arrowPosition === 'left',
                   'arrow-right':arrowPosition === 'right',
                   'arrow-top': arrowPosition === 'top',
                   'arrow-top-right': arrowPosition === 'top-right'}"
       class="arrow"
  >
    <div class="arrow__icon" [class.error]="error">&nbsp;</div>
    <div class="arrow__icon shadow">&nbsp;</div>
  </div>
</div>
