import {Component, Inject, OnInit} from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ErrorMessage} from '../../services/error.service';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ErrorDialogData) {
    dialogRef.disableClose = true
  }

  ngOnInit() {
    this.dialogRef.updatePosition({top: '20vh'})
  }

  Ok() {
    this.dialogRef.close()
  }

}

export interface ErrorDialogData {
  errorMessages: ErrorMessage[]
}
