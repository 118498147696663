import {Pipe, PipeTransform} from '@angular/core'
import {DateUtils, MONTH_NAMES, TimeValue} from '../util/date-utils'

@Pipe({
  name: 'getLabel'
})
export class GetLabelPipe implements PipeTransform {
  transform(value: any, getLabel): string {
    return (getLabel) ? getLabel(value) : value.toString()
  }
}

@Pipe({
  name: 'getMonthLabel'
})
export class GetMonthLabelPipe implements PipeTransform {
  transform(month: Date): string {
    return `${MONTH_NAMES[month.getMonth()]} ${month.getFullYear()}`;
  }
}


@Pipe({
  name: 'formatTimeSpan'
})
export class FormatTimeSpanPipe implements PipeTransform {
  transform(timeSpan: number, zeroTimeToEmptyString: boolean = true): string {
    return DateUtils.formatTimeValue(DateUtils.secondsToTimeValue(timeSpan), zeroTimeToEmptyString)
  }
}

@Pipe({
  name: 'formatTimeValue'
})
export class FormatTimeValuePipe implements PipeTransform {
  transform(timeValue: TimeValue, zeroTimeToEmptyString: boolean = true): string {
    return DateUtils.formatTimeValue(timeValue, zeroTimeToEmptyString)
  }
}
