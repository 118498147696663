import {Injectable} from '@angular/core'
import {MatIconRegistry} from '@angular/material/icon'
import {DomSanitizer} from '@angular/platform-browser'

export enum CustomIcon {
  DIAMOND = 'diamond',
  NOTIFICATIONS_NONE = 'outline-notifications_none-24px',
  NOTIFICATIONS_ACTIVE = 'notifications_active_outlined',
  PUBLISHED_WITH_CHANGES = 'published_with_changes',
  DATE_RANGE = 'date_range_outlined',
  ABSENCE_CHILD = 'absence_child',
  ABSENCE_CHILD_CARE = 'absence_child_care',
  ABSENCE_HOLIDAY = 'absence_holiday',
  ABSENCE_OVERTIME = 'absence_overtime',
  ABSENCE_SICK = 'absence_sick',
  ABSENCE_PARENTAL_LEAVE = 'absence_parental_leave',
  ABSENCE_SPECIAL_LEAVE = 'absence_special_leave',
  ABSENCE_SABBATICAL = 'absence_sabbatical',
  ABSENCE_VACATION = 'absence_vacation',
  ABSENCE_VACATION_HALF = 'absence_vacation_half',
  ABSENCE_VACATION_UNPAID = 'absence_vacation_unpaid',
  ABSENCE_CLEAR = 'absence_clear',
  ABSENCE_SCHOOL = 'absence_school',
  ABSENCE_MATERNITY_PROTECTION = 'absence_maternity-protection',
  ABSENCE_UNKNOWN = 'absence_unknown',
}

@Injectable({
  providedIn: 'root',
})
export class CustomIconsService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
  }

  registerCustomIcons(): void {
    this.addCustomSvgIcon(
      CustomIcon.NOTIFICATIONS_NONE,
      'outline-notifications_none-24px',
    )
    this.addCustomSvgIcon(
      CustomIcon.NOTIFICATIONS_ACTIVE,
      'outline-notifications_active-24px',
    )
    this.addCustomSvgIcon(CustomIcon.DATE_RANGE, 'outline-date_range-24px')

    this.addCustomSvgIcon(CustomIcon.DIAMOND)
    this.addCustomSvgIcon(CustomIcon.ABSENCE_CHILD)
    this.addCustomSvgIcon(CustomIcon.ABSENCE_CHILD_CARE)
    this.addCustomSvgIcon(CustomIcon.ABSENCE_HOLIDAY)
    this.addCustomSvgIcon(CustomIcon.ABSENCE_OVERTIME)
    this.addCustomSvgIcon(CustomIcon.ABSENCE_SICK)
    this.addCustomSvgIcon(CustomIcon.ABSENCE_VACATION)
    this.addCustomSvgIcon(CustomIcon.PUBLISHED_WITH_CHANGES)
    this.addCustomSvgIcon(CustomIcon.ABSENCE_VACATION_HALF)
    this.addCustomSvgIcon(CustomIcon.ABSENCE_PARENTAL_LEAVE)
    this.addCustomSvgIcon(CustomIcon.ABSENCE_SPECIAL_LEAVE)
    this.addCustomSvgIcon(CustomIcon.ABSENCE_SABBATICAL)
    this.addCustomSvgIcon(CustomIcon.ABSENCE_VACATION_UNPAID)
    this.addCustomSvgIcon(CustomIcon.ABSENCE_CLEAR)
    this.addCustomSvgIcon(CustomIcon.ABSENCE_UNKNOWN)
    this.addCustomSvgIcon(CustomIcon.ABSENCE_SCHOOL)
    this.addCustomSvgIcon(CustomIcon.ABSENCE_MATERNITY_PROTECTION)
  }

  private addCustomSvgIcon(name: string, assetName: string = name): void {
    this.matIconRegistry.addSvgIcon(
      name,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `/assets/icons/${assetName}.svg`,
      ),
    )
  }
}

export function customIconExists(icon: string): boolean {
  return CustomIcon[icon] != null
}
