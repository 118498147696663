<div class="message-box">
  <div class="message-box-title">Deine Sitzung ist nicht mehr aktiv</div>
  <div class="message-box-content">
    <div class="margin-right">
      <mat-icon class="material-icons">warning</mat-icon>
    </div>
    <ng-container
      [ngTemplateOutlet]="defaultDialog"></ng-container>
  </div>
  <div class="message-box-actions">
    <button (click)="relog()" class="margin-right" color="primary" mat-flat-button>Erneut einloggen</button>
    <button (click)="tologinScreen()" class="delete-button" color="accent" mat-flat-button>Zur Anmelde Seite</button>
  </div>
</div>

<ng-template #defaultDialog>
  <div>
    <p>Hallo,
      deine Sitzung ist abgelaufen. Solltest du eben noch Daten eingegeben haben, empfehlen wir dir ein erneuten
      login. So können deine Daten sicher gespeichert werden.
    </p>
  </div>
</ng-template>

