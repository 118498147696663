import {Component, ElementRef, EventEmitter, Inject, OnInit, ViewChild} from '@angular/core'
import {DateUtils, MONTH_NAMES} from '../../util/date-utils'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {DateRange, DateRangePicker, DateRangePickerState} from '../../util/date-range-picker'

@Component({
  selector: 'app-month-picker-dialog',
  templateUrl: './month-picker-dialog.component.html',
  styleUrls: ['./month-picker-dialog.component.scss']
})
export class MonthPickerDialogComponent implements OnInit {

  @ViewChild('leftButton', {read: ElementRef, static: true}) leftButton: ElementRef
  @ViewChild('rightButton', {read: ElementRef, static: true}) rightButton: ElementRef

  onMonthPicked: EventEmitter<DateRange>

  selectedYear: number
  readonly monthNames = MONTH_NAMES
  showSelectAllButton: boolean = null
  private range: DateRangePicker

  constructor(private dialogRef: MatDialogRef<MonthPickerDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData) {

  }

  ngOnInit(): void {
    if (this.dialogData) {
      this.setYear(this.dialogData.initialYear)
      this.showSelectAllButton = this.dialogData.showSelectAllButton
      if (this.dialogData.preselected.from) {
        this.range = new DateRangePicker(DateUtils.today, DateRangePickerState.MONTHLY)
      } else {
        this.range = null
        const date = DateUtils.getFirstDayOfMonth(DateUtils.today)
        this.dialogData.preselected = {
          from: date,
          to: date,
        }
      }
      this.pickMonth(this.dialogData.preselectedMonth)
      this.setOnMonthPicked(this.dialogData.onValueChange)
      this.setDateRange(this.dialogData.preselected)
    }
  }

  setYear(year: number): void {
    if (year != null) {
      this.selectedYear = year
    }
  }

  pickMonth(month: number): void {
    if (month != null) {
      const date = new Date(this.selectedYear, month)
      if (!this.range) {
        const rangeDate = new Date(this.selectedYear, month + 1)
        this.range = new DateRangePicker(rangeDate, DateRangePickerState.MONTHLY)
      }
      this.range.pickDate(date)

      this.onMonthPicked?.emit({
        from: this.range.from,
        to: this.range.to,
      })
    }
  }

  setOnMonthPicked(onMonthPicked: EventEmitter<DateRange>): void {
    this.onMonthPicked = onMonthPicked
  }

  isFromDate(month: number): boolean {
    const from = this.range?.from
    const monthDate = new Date(this.selectedYear, month)

    return from != null && DateUtils.isInMonth(monthDate, from)
  }

  isBetweenFromAndTo(month: number): boolean {
    const from = this.range?.from
    const to = this.range?.to
    const monthDate = new Date(this.selectedYear, month)

    return DateUtils.isBetweenDate(monthDate, from, to)
  }

  isToDate(month: number): boolean {
    const to = this.range?.to
    const monthDate = new Date(this.selectedYear, month)

    return to != null && DateUtils.isInMonth(monthDate, to)
  }

  isOnlyDate(month: number): boolean {
    return this.isFromDate(month) && this.range?.to == null
  }

  addToYear(value: number): void {
    this.selectedYear += value
  }

  cancel(): void {
    this.dialogRef.close({
      success: false,
      object: undefined
    })
  }

  clear(): void {
    this.onMonthPicked?.emit({
      from: null,
      to: null,
    })
    this.dialogRef.close({
      success: true,
      object: undefined
    })
  }

  submit(): void {
    this.dialogRef.close({
      success: true,
      object: {
        from: this.range.from,
        to: this.range.to
      }
    })
  }

  shouldDisableSubmitButton(): boolean {
    return this.range?.from == null
  }

  private setDateRange(preselected: DateRange): void {
    if (preselected) {
      if (preselected.from.getMonth() !== preselected.to.getMonth()) {
        this.range?.reset()
        this.range?.pickDate(preselected.from)
        this.range?.pickDate(preselected.to)
      } else {
        this.range?.reset()
        this.range?.pickDate(preselected.from)
      }
    }
  }
}
