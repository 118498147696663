import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  Type
} from '@angular/core'
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {Subscription} from 'rxjs'
import {Rect, ViewUtils} from '../../util/view.utils'

@Directive({
  selector: '[appTooltipDialog]'
})
/**
 * This directive creates a modal dialog after a click on the host item, that is positioned next to the host element.
 * The given Component Type serves as the dialog content. -> use: [appTooltipDialog]=dialogComponent -> TS: dialogComponent = MyDialogComponent
 * With the 'arrowClass' input you can configure the class of the pointer-element (if existing) in the components template.
 * This pointer will be moved to point at the host element.
 * Because the dialog internally uses MatDialog, the given Component can inject MatDialogRef to get access to
 * Observables (like onAfterClosed) and dialog controls (like close).
 * See: https://material.angular.io/components/dialog/api#MatDialogRef
 */

/**
 * EXAMPLE CONFIGURATION in HTML
 * <div id="triggerComponent"
 *     [appTooltipDialog] = dialogComponent  // TS: dialogComponent = MyDialogComponent
 *     [dialogData] = "data"                 // TS: data = "some Data"
 *     dialogPosition = "right"              // initial position is "right" (can change if necessary)
 *     dialogId = "my-tooltip-dialog"
 *     (dialogClosed) = "onDialogClosed($event)"
 *     (dialogOpened) = "onDialogOpened($event)"
 *     [visibleBackdrop] = "true"            // shows backdrop
 *     [hasBackdrop] = "false"               // no backdrop
 * >...
 */

export class TooltipDialogDirective implements OnDestroy {
  @Output() dialogClosed = new EventEmitter<any>()
  @Output() dialogOpened = new EventEmitter<any>()

  @Input('appTooltipDialog') dialogComponent: Type<any>
  @Input() dialogId = 'app-tooltip-dialog'
  @Input() dialogData: any
  @Input() dialogPosition = 'right'
  @Input() visibleBackdrop = false
  @Input() hasBackdrop = true
  @Input() autoFocus = true
  @Input() arrowClass = 'arrow'
  @Input() arrowMargin = 5
  @Input() dialogMargin = 10

  private _isOpen = false
  private dialogRef: MatDialogRef<any, any>
  private subscriptions: Subscription[] = []

  private dialogRect: { top: number; left: number; width: number; height: number }

  constructor(
    private triggerElement: ElementRef,
    private dialog: MatDialog,
    private renderer: Renderer2) {
  }

  public isOpen(): boolean {
    return this._isOpen
  }

  @HostListener('click') onTriggerClick() {
    if (!this.isOpen()) {
      this.createDialog()
    } else {
      this.closeDialog()
    }
  }

  @HostListener('window:resize') onResize() {
    if (this.isOpen() && this.dialogRef) {
      this.repositionDialog()
    }
  }

  // if the dialog has no backdrop it should be closable with environment clicks, too
  @HostListener('document:click', ['$event']) onClickOutside(event) {
    if (this.isOpen() && !this.hasBackdrop) {
      const dialogView = document.getElementById(this.dialogId)
      if (!dialogView.contains(event.target)) {
        let child = event.target
        while (child != null) {
          // TODO find a better solution...
          // cdk-overlay-container contains popups, menus, options and other 'overlays'
          // don't close dialog on click on these, it could be part of the dialogs component ¯\_(ツ)_/¯
          if (child.classList.contains('cdk-overlay-container')) {
            return
          }
          child = child.parentElement
        }
        this.closeDialog()
      }
    }
  }

  ngOnDestroy(): void {
    this.closeDialog()
    this.clearSubscriptions()
  }

  clearSubscriptions() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe()
    })
    this.subscriptions = []
  }

  private closeDialog() {
    if (this.dialogRef) {
      this.dialogRef.close()
      this.onAfterClosed()
    }
  }

  private onAfterClosed() {
    this._isOpen = false
    this.renderer.removeClass(document.body, 'no-scroll')
  }

  private createDialog() {
    this._isOpen = true
    this.clearSubscriptions()
    const config = new MatDialogConfig()
    config.id = this.dialogId
    config.panelClass = 'tooltip-dialog__panel'
    config.hasBackdrop = this.hasBackdrop
    config.autoFocus = this.autoFocus
    if (!this.visibleBackdrop) {
      config.backdropClass = 'tooltip-dialog__backdrop--invisible'
    }
    if (this.dialogData) {
      config.data = this.dialogData
    }

    this.dialogRef = this.dialog.open(this.dialogComponent, config)
    this.subscriptions.push(this.dialogRef.afterOpened().subscribe(() => {
      this.onAfterOpened()
      this.dialogOpened.emit()
    }))
    this.subscriptions.push(this.dialogRef.afterClosed().subscribe((result) => {
      this.onAfterClosed()
      this.dialogClosed.emit(result)
    }))
  }

  private onAfterOpened() {
    this._isOpen = true
    this.renderer.addClass(document.body, 'no-scroll')
    document.getElementById(this.dialogId).addEventListener('click', () => {
      this.repositionDialog()
    })
    this.repositionDialog()
  }


  private repositionDialog() {
    const dialogView = document.getElementById(this.dialogId)
    const triggerRect = this.triggerElement.nativeElement.getBoundingClientRect()
    this.dialogRect = {
      width: dialogView.offsetWidth,
      height: dialogView.offsetHeight,
      top: dialogView.offsetTop,
      left: dialogView.offsetLeft
    }

    const position = new DialogPosition(this.dialogPosition)
    let newDialogRect = position.positionDialog(triggerRect, this.dialogRect, this.dialogMargin)
    while (position.hasToFlip(newDialogRect, this.dialogMargin)) {
      position.nextPosition()
      newDialogRect = position.positionDialog(triggerRect, this.dialogRect, this.dialogMargin)
      if (position.hasPosition(this.dialogPosition)) {
        break
      }
    }
    if (position.hasToMove(newDialogRect, this.dialogMargin)) {
      newDialogRect = position.moveDialog(newDialogRect, this.dialogMargin)
    }

    const arrows = dialogView.getElementsByClassName(this.arrowClass)
    for (let i = 0; i < arrows.length; i++) {
      const arrowRect = arrows[i].getBoundingClientRect()
      const newArrowRect = position.calcArrowPosition(triggerRect, newDialogRect, arrowRect, this.arrowMargin)
      position.positionArrow(arrows[i], newArrowRect, this.renderer)
    }

    this.dialogRect = newDialogRect
    this.dialogRef.updatePosition({
      top: this.dialogRect.top + 'px',
      left: this.dialogRect.left + 'px'
    })
    this.renderer.setStyle(document.getElementById(this.dialogId), 'visibility', 'visible')
  }

}

///////////////////////////////////////////////////////////////////////////////////////////////
// DIALOG POSITIONING
//////////////////////////////////////////////////////////////////////////////////////////////
enum Position {
  TOP = 'top', BOTTOM = 'bottom', LEFT = 'left', RIGHT = 'right'
}

class DialogPosition {
  private position: Position
  private readonly positionChain: Position[]

  constructor(pos: string) {
    switch (pos) {
      case Position.LEFT:
        this.position = Position.LEFT
        this.positionChain = [Position.LEFT, Position.RIGHT, Position.BOTTOM, Position.TOP]
        break
      case Position.RIGHT:
        this.position = Position.RIGHT
        this.positionChain = [Position.RIGHT, Position.LEFT, Position.BOTTOM, Position.TOP]
        break
      case Position.TOP:
        this.position = Position.TOP
        this.positionChain = [Position.TOP, Position.BOTTOM, Position.RIGHT, Position.LEFT]
        break
      case Position.BOTTOM:
        this.position = Position.BOTTOM
        this.positionChain = [Position.BOTTOM, Position.TOP, Position.RIGHT, Position.LEFT]
        break
    }
  }

  public hasPosition(pos: string): boolean {
    return pos === this.position
  }

  public nextPosition(): DialogPosition {
    const index = this.positionChain.indexOf(this.position) + 1
    this.position = this.positionChain[(index >= this.positionChain.length) ? 0 : index]
    return this
  }

  public positionDialog(dialogRect: Rect, triggerRect: Rect, margin: number): Rect {
    switch (this.position) {
      case Position.RIGHT:
        return this.positionRightOnTrigger(dialogRect, triggerRect, margin)
      case Position.LEFT:
        return this.positionLeftOnTrigger(dialogRect, triggerRect, margin)
      case Position.TOP:
        return this.positionTopOnTrigger(dialogRect, triggerRect, margin)
      case Position.BOTTOM:
        return this.positionBottomOnTrigger(dialogRect, triggerRect, margin)
    }
  }

  public hasToFlip(dialogRect, margin): boolean {
    switch (this.position) {
      case Position.RIGHT:
        return this.hasToMoveLeft(dialogRect, margin)
      case Position.LEFT:
        return this.hasToMoveRight(dialogRect, margin)
      case Position.BOTTOM:
        return this.hasToMoveUp(dialogRect, margin)
      case Position.TOP:
        return this.hasToMoveDown(dialogRect, margin)
    }
    return false
  }

  public hasToMove(dialogRect, margin): boolean {
    return this.hasToMoveUp(dialogRect, margin) ||
      this.hasToMoveDown(dialogRect, margin) ||
      this.hasToMoveRight(dialogRect, margin) ||
      this.hasToMoveLeft(dialogRect, margin)
  }

  public moveDialog(dialogRect, margin): Rect {
    const result = this.copyRect(dialogRect)
    const viewportSize = ViewUtils.getViewportSize()
    if (this.hasToMoveDown(dialogRect, margin)) {
      result.top = margin
    }
    if (this.hasToMoveUp(result, margin)) {
      result.top = viewportSize.height - dialogRect.height - margin
    }
    if (this.hasToMoveRight(result, margin)) {
      result.left = margin
    }
    if (this.hasToMoveLeft(result, margin)) {
      result.left = viewportSize.width - dialogRect.width - margin
    }
    return result
  }

  // TODO scroll position has to be included in arrow position... somehow... figure it out!
  public calcArrowPosition(triggerPos, dialogRect, arrowRect, arrowMargin): Rect {
    const result = this.copyRect(arrowRect)
    if (this.position === Position.RIGHT || this.position === Position.LEFT) {
      result.top = Math.abs(triggerPos.top - dialogRect.top) + (triggerPos.height / 2) - (arrowRect.height / 2)
      result.top = (result.top < arrowMargin) ? arrowMargin : result.top
      result.top = (result.top + arrowRect.height + arrowMargin > dialogRect.height) ?
        dialogRect.height - arrowMargin - arrowRect.height : result.top
      return result
    } else {
      result.left = Math.abs(triggerPos.left - dialogRect.left) + (triggerPos.width / 2) - (arrowRect.width / 2)
      result.left = (result.left < arrowMargin) ? arrowMargin : result.left
      result.left = (result.left + arrowRect.width + arrowMargin > dialogRect.width) ?
        dialogRect.width - arrowMargin - arrowRect.width : result.left
      return result
    }
  }

  positionArrow(arrow: Element, newArrowRect: Rect, renderer: Renderer2) {
    renderer.removeStyle(arrow, 'top')
    renderer.removeStyle(arrow, 'left')
    for (const pos of Object.values(Position)) {
      renderer.removeClass(arrow, 'arrow-' + pos)
    }
    renderer.addClass(arrow, 'arrow-' + this.swap(this.position))

    if (this.position === Position.RIGHT || this.position === Position.LEFT) {
      renderer.setStyle(arrow, 'top', newArrowRect.top + 'px')
    } else {
      renderer.setStyle(arrow, 'left', newArrowRect.left + 'px')
    }
  }

  private copyRect(dialogRect): Rect {
    return {top: dialogRect.top, height: dialogRect.height, left: dialogRect.left, width: dialogRect.width}
  }

  private positionRightOnTrigger = (triggerRect, dialogRect, margin) => {
    const result = this.copyRect(dialogRect)
    result.top = (triggerRect.top + triggerRect.height / 2) - dialogRect.height / 2
    result.left = triggerRect.left + triggerRect.width + margin
    return result
  }

  private positionLeftOnTrigger = (triggerRect, dialogRect, margin) => {
    const result = this.copyRect(dialogRect)
    result.top = (triggerRect.top + triggerRect.height / 2) - dialogRect.height / 2
    result.left = triggerRect.left - dialogRect.width - margin
    return result
  }

  private positionTopOnTrigger = (triggerRect, dialogRect, margin) => {
    const result = this.copyRect(dialogRect)
    result.top = triggerRect.top - dialogRect.height - margin
    result.left = (triggerRect.left + triggerRect.width / 2) - dialogRect.width / 2
    return result
  }

  private positionBottomOnTrigger = (triggerRect, dialogRect, margin) => {
    const result = this.copyRect(dialogRect)
    result.top = triggerRect.top + triggerRect.height + margin
    result.left = (triggerRect.left + triggerRect.width / 2) - dialogRect.width / 2
    return result
  }

  private hasToMoveUp(dialogRect, margin): boolean {
    const viewportSize = ViewUtils.getViewportSize()
    return dialogRect.top + dialogRect.height + margin > viewportSize.height
  }

  private hasToMoveDown(dialogRect, margin): boolean {
    return dialogRect.top - margin < 0
  }

  private hasToMoveLeft(dialogRect, margin): boolean {
    const viewportSize = ViewUtils.getViewportSize()
    return dialogRect.left + dialogRect.width + margin > viewportSize.width
  }

  private hasToMoveRight(dialogRect, margin): boolean {
    return dialogRect.left - margin < 0
  }

  private swap(pos: string): Position {
    switch (pos) {
      case Position.TOP:
        return Position.BOTTOM
      case Position.BOTTOM:
        return Position.TOP
      case Position.LEFT:
        return Position.RIGHT
      case Position.RIGHT:
        return Position.LEFT
    }
  }
}
