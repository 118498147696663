import {
  AfterViewInit,
  Component,
  ContentChildren,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core'
import {MatPaginator, MatPaginatorIntl, PageEvent} from '@angular/material/paginator'
import {MatSort, MatSortable} from '@angular/material/sort'
import {MatTableDataSource} from '@angular/material/table'
import {mergeSort} from '../../util/sort-utils'
import {compareStringWithUmlauts} from '../../util/string-utils'
import {LocalUserPropertiesService} from '../../services/local-user-properties.service'

@Component({
  selector: 'app-sortable-table',
  templateUrl: './sortable-table.component.html',
  styleUrls: ['./sortable-table.component.scss']
})
export class SortableTableComponent implements OnInit, AfterViewInit, OnChanges {

  selectedEntry: any = undefined

  sortStateAsc = SortState.ASCENDING
  sortStateDes = SortState.DESCENDING

  selectedPageSize: number

  @ContentChildren(TemplateRef)
  dataElements: QueryList<TemplateRef<any>>

  @Input()
  filter = ''
  @Input()
  filterPredicate: (any, string) => boolean

  @Input() sortingColumn ?: string

  @Input()
  data: any[]
  dataAvailable = false
  dataSource = new MatTableDataSource([])

  @Input()
  columnDefinitions: CustomColumnDefinition[]
  internalColumnDefinitions: InternalCustomColumnDefinition[]

  @ViewChild(MatSort, {static: true}) sort: MatSort
  lastSortedColumn: string

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator

  @Input()
  columnSizes: string[]

  @Input() key: string

  constructor(private localUserPropertiesService: LocalUserPropertiesService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data != null) {
      this.updateDataSource(changes.data.currentValue)
    }

    if (changes.filter != null) {
      this.updateFilter(changes.filter.currentValue)
    }

    if (changes.filterPredicate != null) {
      this.updateFilterPredicate(changes.filterPredicate.currentValue)
    }
    if (changes.sortingColumn != null) {
      if (this.lastSortedColumn != changes.sortingColumn.currentValue) {
        if (!changes.sortingColumn.isFirstChange()) {
          this.sortTableData(changes.sortingColumn.currentValue)
        }
      }
    }

  }

  getColumnSize(index: number): string {
    if (this.columnSizes != null && this.columnSizes.length > index) {
      return this.columnSizes[index]
    }
    return ''
  }

  async ngOnInit(): Promise<void> {
    this.initializeColumnSort()

    const columnDefinition = this.columnDefinitions.find(columnDef => columnDef.sortable && columnDef.initialSort)
    this.sortTableData(
      this.sortingColumn ? this.sortingColumn :
        ((columnDefinition != null) ? columnDefinition.fieldName : this.columnDefinitions[0].fieldName)
    )

    this.selectedPageSize = await this.localUserPropertiesService.getProperty(this.key, 12)
    requestAnimationFrame(() => this.updateDataSource(this.data))
  }

  ngAfterViewInit(): void {
    const paginatorIntl = new MatPaginatorIntl()
    paginatorIntl.itemsPerPageLabel = 'Einträge pro Seite'
    this.paginator._intl = paginatorIntl

    this.dataSource.sort = this.sort
    this.dataSource.filterPredicate = this.filterPredicate
    this.dataSource.paginator = this.paginator
    const defaultSortData = this.dataSource.sortData
    this.dataSource.sortData = (data, sort: MatSort) => {
      if (data.length > 0 && typeof data[0][sort.active] === 'string') {
        return this.customSortFunction(data, sort)
      } else {
        return defaultSortData(data, sort)
      }
    }
    if (this.dataSource.paginator != null) {
      this.dataSource.paginator._intl.itemsPerPageLabel = 'Einträge pro Seite'
    }
  }

  updateDataSource(data: any): void {
    this.data = data

    if (data != null) {
      this.dataSource.data = this.data
      this.dataAvailable = true
    }
  }

  sortData(sortEvent: any): void {
    this.internalColumnDefinitions.forEach(internalColumnDef => {
      internalColumnDef.sortState =
        (internalColumnDef.fieldName == sortEvent.active) ? this.mapDirectionToSortState(sortEvent.direction) : SortState.NEUTRAL
    })
  }

  mapDirectionToSortState(direction: string): SortState {
    if (direction === 'asc') {
      return SortState.ASCENDING
    } else if (direction === 'desc') {
      return SortState.DESCENDING
    } else {
      return SortState.NEUTRAL
    }
  }

  sortTableData(column: string): void {
    if (this.sort) {
      this.sort.sort(
        {
          disableClear: true,
          id: column,
          start: 'asc'
        } as MatSortable)
      this.lastSortedColumn = column
    }
  }

  getHeaderRowDefinition(): string[] {
    return this.columnDefinitions.map(columnDef => columnDef.displayName)
  }

  getTemplateOfColumn(column: CustomColumnDefinition) {
    // TODO hacky. needs rework. => https://stackoverflow.com/questions/60191471/get-viewchildren-template-by-id
    return this.dataElements.find(t => (<any> t)._declarationTContainer?.localNames?.includes(column.displayName))
  }

  select(entry): void {
    this.selectedEntry = entry
  }

  isSelectedRow(row): boolean {
    return this.selectedEntry == row
  }

  onSelectPageSize(event: PageEvent): void {
    this.localUserPropertiesService.setProperty(this.key, event.pageSize).then()
  }

  private customSortFunction = (data, sort: MatSort): any[] => {
    return mergeSort(data, sort, compareStringWithUmlauts)
  }

  private updateFilter(filter: string): void {
    this.filter = filter
    this.dataSource.filter = this.filter
  }

  private updateFilterPredicate(filterPredicate: (any, string) => boolean): void {
    this.filterPredicate = filterPredicate
    this.dataSource.filterPredicate = this.filterPredicate
  }

  private initializeColumnSort(): void {
    if (this.columnDefinitions.length === 0) {
      throw new Error('SortableTableComponent should have at least one column')
    }

    this.internalColumnDefinitions = this.columnDefinitions.map(columnDef => {
      return {
        ...columnDef,
        sortState: SortState.NEUTRAL
      }
    })

    this.internalColumnDefinitions[0].sortState = SortState.ASCENDING
  }
}

interface InternalCustomColumnDefinition extends CustomColumnDefinition {
  sortState: SortState
}

export interface CustomColumnDefinition {
  displayName: string
  sortable: boolean
  fieldName: string
  initialSort: boolean
}

export enum SortState {
  ASCENDING = 'expand_less',
  DESCENDING = 'expand_more',
  NEUTRAL = 'unfold_more'
}
