import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core'
import {UntypedFormControl} from '@angular/forms'
import {toUserEntry, UserEntry, UserService} from '../../services/repository/user.service'
import {map, startWith} from 'rxjs/operators'

@Component({
  selector: 'app-user-selection',
  templateUrl: './user-selection.component.html',
  styleUrls: ['./user-selection.component.scss']
})
export class UserSelectionComponent implements OnInit, OnChanges {
  userAutocompleteControl = new UntypedFormControl()
  userOptions: UserEntry[] = []
  filteredUserOptions: UserEntry[]
  @Input() usersToFilter = []
  @Input() placeholderText = 'Mitarbeiter auswählen'
  @Input() displayName: string = null
  @Output() userSelected = new EventEmitter<UserEntry>()
  private selecteduser: UserEntry = null

  value: string

  constructor(private userService: UserService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.usersToFilter != null) {
      if (changes.usersToFilter.currentValue != changes.usersToFilter.previousValue) {
        this.filterAndClear()
      }
    }
  }

  ngOnInit(): void {
    if (this.displayName) {
      this.userAutocompleteControl.setValue({name: this.displayName})
    }
    this.userService.requestAll().then(users => {
      users.forEach(user => {
        this.userOptions.push(toUserEntry(user))
      })
      if (this.usersToFilter?.length > 0) {
        this.filterAndClear()
      }
      this.userAutocompleteControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterUserOptions(value))
      )
    })
    this.userAutocompleteControl.valueChanges.subscribe((value) => {
      this.filteredUserOptions = this._filterUserOptions(value)
    })
  }

  _filterUserOptions(value): UserEntry[] {
    if (value == null) {
      return this.userOptions.filter((option) => this.usersToFilter.includes(option.id))
    }
    let filterValue = ''
    if (this.isUserEntry(value)) {
      filterValue = value.name.toLowerCase()
    } else {
      filterValue = value.toLowerCase()
    }
    return this.userOptions.filter(option => option.name.toLowerCase().includes(filterValue) && this.usersToFilter.includes(option.id))
  }

  clearInput(): void {
    if (this.selecteduser != null) {
      if (this.usersToFilter.length === 0 || !this.usersToFilter.includes(this.selecteduser?.id)) {
        this.onClear()
      }
    }
  }

  onClear(): void {
    this.userAutocompleteControl.setValue('')
    this.selecteduser = null
  }


  emitUser(value): void {
    if (this.isUserEntry(value)) {
      this.selecteduser = value
    } else {
      this.selecteduser = null
    }
    this.userSelected.emit(this.selecteduser)
  }

  isUserEntry(value): value is UserEntry {
    return value && (value as UserEntry).name !== undefined
  }

  displayUserEntry(option: UserEntry): string {
    return option ? option.name : ''
  }

  filterAndClear(): void {
    this.filteredUserOptions = this._filterUserOptions(this.userAutocompleteControl.value)
    this.clearInput()
  }
}
