<div class="toggle-container">
  <button (click)="toggle(toggleInfo.text)"
          *ngFor="let toggleInfo of toggleButtonInfos"
          [class.first]="isFirst(toggleInfo)"
          [class.last]="isLast(toggleInfo)"
          [class.toggled]="toggleInFocus == toggleInfo.text"
          class="toggle no-select"
          [disabled]="disabled"
          [class.disabled]="disabled"
          id="{{toggleInfo.text | lowercase}}">
    <ng-container *ngIf="toggleInfo.icon != ''"
                  [ngTemplateOutletContext]="{ $implicit: toggleInfo.icon }"
                  [ngTemplateOutlet]="
                              (getLocallyStoredToggleIcon(toggleInfo.icon) == '')
                                    ? remoteIcon
                                    : localIcon"
                  class="toggle-icon"></ng-container>
    <span [class.no-icon]="toggleInfo.icon == ''"
          class="toggle-text">{{toggleInfo.text}}</span>
  </button>
</div>

<ng-template #localIcon let-icon>
  <div class="toggle-icon-container">
    <mat-icon [svgIcon]="icon" class="toggle-icon"></mat-icon>
  </div>
</ng-template>

<ng-template #remoteIcon let-icon>
  <div class="toggle-icon-container">
    <mat-icon class="toggle-icon asdasd">{{icon}}</mat-icon>
  </div>
</ng-template>
