import {Injectable} from '@angular/core'
import {DateRange} from '../util/date-range-picker';
import {UserEntry} from './repository/user.service';

@Injectable({
  providedIn: 'root'
})
export class VerifyFilterGlobalsService {

  dateRange: DateRange = null;
  selectedUSer: UserEntry = null;
  verifyFunction: VerifyView = VerifyView.PRUEFEN;
  shouldFetchData = false;

  constructor() {
  }

}

export enum VerifyView {
  PRUEFEN = 'Prüfen',
  SUCHEN = 'Suchen'
}
