<div *ngIf="isSubmitted == undefined; else submittedLoaded"> Hello</div>
<!--
  Go to https://github.com/angular/material2/blob/master/src/lib/autocomplete/autocomplete.md to get more information
  about autocompletion styling
-->
<ng-template #submittedLoaded>
  <div>
    <div #container="matAutocompleteOrigin"
         [style.border-color]='isSubmitted ? "#7f7f7f" : "#006e96"'
         class="smart-adding-container"
         matAutocompleteOrigin>
      <div class="input-container">
        <input #input (focusin)="updateActions()" *ngIf="!isSubmitted; else submitted"
               [formControl]="autocompleteFormControl"
               [matAutocompleteConnectedTo]="container"
               [matAutocomplete]="auto"
               matInput
               maxlength="1000"
               placeholder="Thema hinzufügen"
               type="text">
      </div>
    </div>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected()" [autoActiveFirstOption]="true">
      <mat-option (mouseenter)="hoverOption(i)" *ngFor="let option of availableOptions | async; let i = index"
                  [value]="option"
                  class="app-option no-line-height">
        <div class="horizontal-layout"
             [title]="option.tooltip">
             <img  [src]="option.icon"
             alt="Customer logo"
             class="mat-icon"
             onerror="this.src='/assets/icons/customer-logo-placeholder.png'">
          <span>
            <span class="label">{{option.label}}</span><br/>
            <span class="sublabel">{{option.subLabel}}</span>
          </span>
        </div>
      </mat-option>
    </mat-autocomplete>
  </div>
</ng-template>

<ng-template #submitted>
  <input [matAutocomplete]="matAutocomplete" class="not-usable-add"
         disabled="true"
         matInput
         placeholder="Monat ist bereits abgegeben"
         type="text">
</ng-template>
