import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {GraphqlCollectorService} from '../http/graphql-collector.service';
import {SessionService} from '../session.service';
import {GraphQLQuery} from '../../util/graphql-executor';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private repoURL: string

  constructor(private httpClient: HttpClient,
              private graphQLService: GraphqlCollectorService,
              private sessionService: SessionService) {
    this.repoURL = `${environment.serverUrl}/customers`
    sessionService.loginData$.subscribe(loginData => {
      if (loginData.isValid) {
        this.requestAll();
      }
    });
  }

  private allSubject$ = new BehaviorSubject<CustomerEntity[]>(undefined);

  get all$(): Observable<CustomerEntity[]> {
    return this.allSubject$;
  }

  static allGraphsQLQuery(): GraphQLQuery {
    return {
      function: 'customers',
      variables: [],
      fieldBody: CUSTOMER_ENTITY_TEMPLATE
    };
  }

  requestAll(): void {
    this.graphQLService
      .query(CustomerService.allGraphsQLQuery())
      .subscribe(response => this.allSubject$.next(response));
  }

  /* APIs below are not yet converted to BloC pattern */

  get(id: number): Observable<CustomerEntity> {
    return this.httpClient.get(`${this.repoURL}/${id}`) as Observable<CustomerEntity>;
  }

  update(customer: CustomerEntity): void {
    this.httpClient.put(`${this.repoURL}/${customer.id}`, customer).subscribe(() => this.requestAll());
  }

  create(newCustomer: CustomerEntity): void {
    this.httpClient.post(this.repoURL, newCustomer).subscribe(() => this.requestAll());
  }

  delete(customer: CustomerEntity): void {
    customer.archived = true;
    this.httpClient.put(`${this.repoURL}/${customer.id}`, customer).subscribe(() => this.requestAll());
  }

  restore(customer: CustomerEntity): void {
    customer.archived = false;
    this.update(customer);
  }

}

export interface  CustomerEntity {
  id?: number;
  name: string;
  description: string;
  icon: string;
  archived?: boolean;
}

export const CUSTOMER_ENTITY_TEMPLATE =
  `
    id,
    name,
    icon,
    description,
    archived
 `;
