<div class="chooseDates">
  <div>
    <div class="row">
      <div *ngFor="let dayCharacter of weekDaysInitialCharacter" class="cell weekday">{{dayCharacter}}</div>
    </div>
    <div class="calendar-info-box">
      <div *ngFor="let week of calendar.weeks" class="row">
        <div (click)="selectedDateChanged(date)" *ngFor="let date of week.days"
             [class.is-day-in-month-submitted]="isDateInMonthSubmitted(date)"
             [class.is-end-of-date-range]="isEndOfDateRange(date)"
             [class.is-in-absence-span]="isInDateRange(date)"
             [class.is-start-of-date-range]="isStartOfDateRange(date)"
             [class.is-today]="date.isToday"
             [class.is-weekend-day]="isWeekendDay(date)&&isInDateRange(date)"
             [class.not-belong-to-month]="!date.belongsToMonth"
             class="cell">
          <span class="text-center text-middle calendar-day"
                [class.disabled]="!isDateInPast(date.dateObject) && !possibleToSelectDateInPast && !isStartOfDateRange(date)">
            {{date.day}}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>


