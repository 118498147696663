import {Component, EventEmitter, OnInit, Output} from '@angular/core'
import {DateRange, DateRangePicker} from '../../util/date-range-picker'
import {BehaviorSubject, Observable} from 'rxjs'
import {DateUtils, MONTH_NAMES} from '../../util/date-utils'

@Component({
  selector: 'app-daterange-picker',
  templateUrl: './daterange-picker.component.html',
  styleUrls: ['./daterange-picker.component.scss']
})
export class DaterangePickerComponent implements OnInit {
  popupVisible = false

  @Output() selectedDateRange = new EventEmitter<SelectedDateRange>()

  dateRangePicker: DateRangePicker = new DateRangePicker()
  private previousValue: DateRange = null

  private _leftMonth$: BehaviorSubject<Date>
  private _rightMonth$: BehaviorSubject<Date>

  constructor() {
  }


  ngOnInit(): void {
    this.initializeSubjects()
    this.initializeCalendarData()
  }

  initializeCalendarData(): void {
    const leftMonth = DateUtils.today
    leftMonth.setDate(1)
    const rightMonth = DateUtils.getNextMonth(leftMonth)
    this._leftMonth$.next(leftMonth)
    this._rightMonth$.next(rightMonth)
  }

  private initializeSubjects(): void {
    const date = DateUtils.today
    this._rightMonth$ = new BehaviorSubject<Date>(DateUtils.getNextMonth(date))
    this._leftMonth$ = new BehaviorSubject<Date>(date)
  }

  get leftMonth(): Date {
    return new Date(this._leftMonth$.value)
  }

  get leftCalendarMonth$(): Observable<Date> {
    return this._leftMonth$
  }

  get rightMonth(): Date {
    return new Date(this._rightMonth$.value)
  }

  get rightCalendarMonth$(): Observable<Date> {
    return this._rightMonth$
  }

  togglePopup(): void {
    this.popupVisible = !this.popupVisible
  }

  onSelectDate(selectedDate: Date): void {
    this.dateRangePicker.pickDate(selectedDate)
  }

  getLeftMonthLabel(): string {
    return `${MONTH_NAMES[this.leftMonth.getMonth()]} ${this.leftMonth.getFullYear()}`
  }

  getRightMonthLabel(): string {
    return `${MONTH_NAMES[this.rightMonth.getMonth()]} ${this.rightMonth.getFullYear()}`
  }

  prevMonth(): void {
    const newRightMonth = this.leftMonth
    const newLeftMonth = DateUtils.getPreviousMonth(newRightMonth)

    this._rightMonth$.next(newRightMonth)
    this._leftMonth$.next(newLeftMonth)
  }

  nextMonth(): void {
    const newLeftMonth = this.rightMonth
    const newRightMonth = DateUtils.getNextMonth(newLeftMonth)

    this._rightMonth$.next(newRightMonth)
    this._leftMonth$.next(newLeftMonth)
  }

  cancel(): void {
    this.dateRangePicker.setDateRange({...this.previousValue})
    this.popupVisible = false
  }

  formatDate(date): string {
    return DateUtils.formatDate(date)
  }


  acceptSelectedDate(): void {
    this.previousValue = {from: this.dateRangePicker.from, to: this.dateRangePicker.to}
    const dateRange: DateRange = {from: null, to: null}
    dateRange.from = this.dateRangePicker.from
    dateRange.to = this.dateRangePicker.to != null ? this.dateRangePicker.to : this.dateRangePicker.from

    this.selectedDateRange.emit(dateRange)
    this.popupVisible = false
  }
}

export interface SelectedDateRange {
  from: Date
  to: Date
}
