import {Injectable} from '@angular/core'
import {ApplicationInsights, IEventTelemetry, IExceptionTelemetry} from '@microsoft/applicationinsights-web'
import {ICustomProperties} from '@microsoft/applicationinsights-core-js'
import {RemoteConfigurationService} from './repository/remote-configuration.service'

@Injectable({
  providedIn: 'root'
})

export class AzureLogging {
  private static appInsights: ApplicationInsights = null


  constructor(private remoteConfigService: RemoteConfigurationService) {
    this.remoteConfigService.applicationInsightInstrumentationKey$.subscribe((key) => {
      if (key) {
        AzureLogging.initalize(key)
      }
    })
  }

  public static logError(error: Error) {
    if (this.appInsights) {
      const wrappedError = {
        id: '',
        exception: error,
      } as IExceptionTelemetry
      this.appInsights.trackException(wrappedError)
    }
  }

  public static logEvent(event: IEventTelemetry, customProperties?: ICustomProperties) {
    if (this.appInsights) {
      this.appInsights.trackEvent(event, customProperties)
    }
  }

  private static initalize(key) {
    if (AzureLogging.appInsights == null) {
      AzureLogging.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: key,
          enableAutoRouteTracking: true,
          sessionRenewalMs: 180000
        }
      })
      AzureLogging.appInsights.loadAppInsights()
    }

  }


}
