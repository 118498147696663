import {EventEmitter, Injectable} from '@angular/core'
import {Observable} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class AppFocusService {

  private _appFocus$ = new EventEmitter<void>()
  private _appblur$ = new EventEmitter<void>()

  constructor() {
  }

  get onAppFocus$(): Observable<any> {
    return this._appFocus$
  }


  get onAppBlur$(): Observable<any> {
    return this._appblur$
  }

  notifyAppFocus() {
    this._appFocus$.next()
  }

  notifyAppBlur() {
    this._appblur$.next()
  }
}
