import {MatSort} from '@angular/material/sort'

/* mergeSort for tableData */
export function mergeSort(unsortedArray: any[], sort: MatSort, compare = compareAny) {

  if (unsortedArray.length <= 1) {
    return unsortedArray
  }

  const middle = Math.floor(unsortedArray.length / 2)
  const left = unsortedArray.slice(0, middle)
  const right = unsortedArray.slice(middle)

  return merge(
    mergeSort(left, sort, compare), mergeSort(right, sort, compare), sort, compare
  )
}

function merge(left, right, sort: MatSort, compare) {
  const resultArray = []
  let leftIndex = 0, rightIndex = 0

  while (leftIndex < left.length && rightIndex < right.length) {
    const isLeftSmallerThanRight = compare(left[leftIndex][sort.active], right[rightIndex][sort.active])
    const isSortDirectionDescending = sort.direction == 'desc'
    const shouldInsertFromLeft = isLeftSmallerThanRight !== isSortDirectionDescending
    if (shouldInsertFromLeft) {
      resultArray.push(left[leftIndex])
      leftIndex++
    } else {
      resultArray.push(right[rightIndex])
      rightIndex++
    }
  }
  return resultArray
    .concat(left.slice(leftIndex))
    .concat(right.slice(rightIndex))
}

function compareAny(one: any, other: any) {
  return one < other
}
