import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {DateUtils, MONTH_NAMES} from '../../util/date-utils'
import {DateRange} from '../../util/date-range-picker'
import {MonthPickerDialogComponent} from '../month-picker-dialog/month-picker-dialog.component'
import {deepCopy} from '../../util/copy'

@Component({
  selector: 'app-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss']
})
export class MonthPickerComponent implements OnInit {

  monthPickerDialog = MonthPickerDialogComponent

  @Output()
  monthRangePicked = new EventEmitter<DateRange>()

  @Input()
  preselectedRange: DateRange

  @Input() showSelectAllButton: boolean = null

  constructor() {
  }

  private _range: DateRange

  get range(): DateRange {
    return deepCopy(this._range)
  }

  ngOnInit(): void {
    if (this.preselectedRange == null) {
      const from = DateUtils.getFirstDayOfMonth(DateUtils.today)
      from.setMonth(from.getMonth() - 1)
      this._range = {
        from,
        to: DateUtils.getEndOfDay(DateUtils.getLastDayOfMonth(from))
      }
    } else {
      if (!this.preselectedRange.from) {
        this._range = {
          from: null,
          to: null
        }
      } else {
        this._range = {
          from: DateUtils.getFirstDayOfMonth(this.preselectedRange.from),
          to: (this.preselectedRange.to != null)
            ? DateUtils.getEndOfDay(DateUtils.getLastDayOfMonth(this.preselectedRange.to))
            : DateUtils.getEndOfDay(DateUtils.getLastDayOfMonth(this.preselectedRange.from))
        }
      }
    }
    this.monthRangePicked.emit(this._range)
  }

  getLabel(data: any): string {
    function displayDateString(date: Date): string {
      return (date != null) ? MONTH_NAMES[date.getMonth()] + ' ' + date.getFullYear() : ''
    }

    if (data.from != null) {
      if (data.to != null) {
        const fromString = displayDateString(data.from)
        const toString = (!DateUtils.isInMonth(data.to, data.from) ? (' \u2014 ' + displayDateString(data.to)) : '')
        return fromString + toString
      } else {
        return displayDateString(data.from)
      }
    }

    return ''
  }

  getDialogData(): any {
    const initialYear = (this._range.from != null) ? this._range.from.getFullYear() : DateUtils.today.getFullYear()
    return {
      initialYear,
      onValueChange: this.monthRangePicked,
      preselected: {
        from: this._range.from,
        to: this._range.to
      },
      showSelectAllButton: this.showSelectAllButton,
    }
  }

  setDateRange(range: DateRange): void {
    this._range.from = range.from
    this._range.to = range.to
  }

  onDropDownResult(result: any): void {
    if (result && result.success) {
      this._range.from = result.object.from
      if (result.object.from) {
        if (result.object.to) {
          this._range.to = result.object.to
        } else {
          this._range.to = new Date(DateUtils.getLastDayOfMonth(this._range.from))
        }
        this.monthRangePicked.emit(this._range)
      }
    } else {
      this.monthRangePicked.emit(this._range)
    }
  }
}
