import {Component, Inject, OnInit} from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  title: string
  typeMap = new Map<ConfirmDialogType, any>()
  text: string
  currentTypeData

  constructor(private dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: ConfirmDialogData) {
    this.typeMap.set(ConfirmDialogType.INFO, {
      title: 'Hinweis!',
      icon: 'help_outlined',
      color: '#006E96'
    })

    this.typeMap.set(ConfirmDialogType.WARNING, {
      title: 'Achtung',
      icon: 'warning_outlined', // TODO:
      color: '#F0D24B'
    })

    this.typeMap.set(ConfirmDialogType.ERROR, {
      title: 'Server Fehler!',
      icon: 'error_outlined',
      color: '#EB193C'
    })

    this.text = data.text
    if (data.type != null) {
      this.currentTypeData = this.typeMap.get(data.type)
    } else {
      this.currentTypeData = this.typeMap.get(ConfirmDialogType.ERROR)
    }

    this.title =
      (data.title != null) ? data.title : this.currentTypeData.title
  }

  ngOnInit() {

  }

}

export enum ConfirmDialogType {
  ERROR,
  WARNING,
  INFO,
}

export interface ConfirmDialogData {
  title: string
  text: string
  type: ConfirmDialogType
}
