<div class="pagerContainer">
  <button
    (click)="navigate(-1)"
    [class.hide]="currentValue == 1"
    class="mat-icon-button pagerElement"
    id="buttonLeft"
    title="Zurück"
    mat-mini-fab
    disableRipple="true"
  >
    <mat-icon class="pagerIcon">
      keyboard_arrow_left
    </mat-icon>
  </button>
  <div [class.hide]="!showNumberOfPages" class="pagerElement" id="pagerLabelContainer">
    {{ currentValue }} / {{ numberOfPages }}
  </div>
  <button
    (click)="navigate(1)"
    [class.hide]="currentValue == numberOfPages"
    class="mat-icon-button pagerElement"
    id="buttonRight"
    title="Weiter"
    mat-mini-fab
    disableRipple="true"
  >
    <mat-icon class="pagerIcon">
      keyboard_arrow_right
    </mat-icon>
  </button>
</div>
