import {Component, Inject, OnInit} from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ErrorDialogData} from '../error-dialog/error-dialog.component'

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit {

  public static readonly RESULT_RELOG = 'relog'
  public static readonly TO_LOGIN_SCREEN = 'toLoginScreen'


  constructor(
    private dialogRef: MatDialogRef<LoginDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ErrorDialogData) {
  }

  ngOnInit() {
    this.dialogRef.updatePosition({top: '20vh'})
  }

  relog() {
    this.dialogRef.close(LoginDialogComponent.RESULT_RELOG)
  }

  tologinScreen() {
    this.dialogRef.close(LoginDialogComponent.TO_LOGIN_SCREEN)
  }


}

export interface LoginDialogData {

}
