export function compareStringWithUmlauts(one: string, other: string): boolean {

  one = one != null ? one : '';
  other = other != null ? other : '';

  const oneString = replaceUmlauts(one.toLowerCase());
  const otherString = replaceUmlauts(other.toLowerCase());

  return oneString < otherString;
}

export function replaceUmlauts(str: string): string {
  // ordering of names (e.g. in phone books) specified by DIN 5007 Variant 2
  return str.replace('ä', 'ae')
    .replace('ö', 'oe')
    .replace('ü', 'ue')
    .replace('ß', 'ss');
}
