import {Subject} from 'rxjs'

export class InterceptableSubject<T> extends Subject<T> {
  interceptors: Interceptor<T>[] = []

  addInterceptor(interceptor: Interceptor<T>) {
    this.interceptors.push(interceptor)
  }

  next(value: T) {
    Promise
      .all(this.interceptors.map((interceptor) => interceptor(value)))
      .then(returnValues => {
        const success = returnValues.every((returnValue) => returnValue)
        if (success) {
          super.next(value)
        }
      })
  }

  reset() {
    this.interceptors = []
  }
}

export type Interceptor<T> = (t: T) => Promise<boolean>
