<div class="filter">
  <div class="filter-input" (click)="togglePopup()">
    <div class="placeholder" *ngIf="dateRangePicker.from == null; else dateRange ">
      Bitte Zeitraum auswählen
    </div>
  </div>
  <button (click)="togglePopup()" class="filter-button" mat-mini-fab>
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>
  <div *ngIf="popupVisible" class="popup">
    <div class="calendar-container">
      <div class="left calendar">
        <div class="month-container">
          <div class="month-container__month">
            {{leftMonth | getMonthLabel}}
          </div>
          <div class="month-container__navicon">
            <mat-icon (click)="prevMonth()" class="clickable"
                      title="Vorheriger Monat">
              chevron_left
            </mat-icon>
          </div>
        </div>
        <app-date-picker (selectedDate)="onSelectDate($event)"
                         [currentMonthObservable]="leftCalendarMonth$"
                         [dateRangePicker]="dateRangePicker"
                         [ignoreConstraints]="true" ></app-date-picker>
      </div>
      <div class="right calendar">
        <div class="month-container">
          <div class="month-container__month">
            {{rightMonth | getMonthLabel}}
          </div>
          <div class="month-container__navicon">
            <mat-icon (click)="nextMonth()" class="clickable"
                      title="Nächster Monat">
              chevron_right
            </mat-icon>
          </div>
        </div>
        <app-date-picker (selectedDate)="onSelectDate($event)"
                         [currentMonthObservable]="rightCalendarMonth$"
                         [dateRangePicker]="dateRangePicker"
                         [ignoreConstraints]="true" ></app-date-picker>
      </div>
    </div>
    <div class="button__container">
      <div class="abort-button">
        <button (click)="cancel()" mat-button>Abbrechen</button>
      </div>
      <div class="create-entries-button">
        <button (click)="acceptSelectedDate()"
                color="primary" mat-flat-button>Hinzufügen
        </button>
      </div>
    </div>
  </div>
</div>


<ng-template #dateRange>
  <div class="row">
    <div>
      {{formatDate(dateRangePicker.from)}}
    </div>
    <div *ngIf="dateRangePicker.to != null">
      &nbsp;-&nbsp;{{formatDate(dateRangePicker.to)}}
    </div>
  </div>
</ng-template>
