import {Injectable} from '@angular/core'
import {BehaviorSubject, Observable} from 'rxjs'
import {DateUtils} from '../util/date-utils'
import {AppFocusService} from './app-focus-service.service';

@Injectable({
  providedIn: 'root'
})
export class TimeNavigationService {

  private dateLastRefresh: Date;

  private _currentMonth$ = new BehaviorSubject<Date>(DateUtils.today)

  get currentMonth$(): Observable<Date> {
    return this._currentMonth$
  }

  private _currentDay$ = new BehaviorSubject<Date>(DateUtils.today)

  get currentDay$(): Observable<Date> {
    return this._currentDay$
  }

  get currentMonth(): Date {
    return new Date(+this._currentMonth$.getValue())
  }

  get previousMonth(): Date {
    const month = new Date(this.currentMonth)
    month.setMonth(this.currentMonth.getMonth()-1)
    return  month
  }

  get currentDay(): Date {
    return new Date(+this._currentDay$.getValue())
  }

  get today(): Date {
    return DateUtils.today
  }

  constructor(private appFocusService: AppFocusService) {
    this.dateLastRefresh = DateUtils.today;
    appFocusService.onAppFocus$.subscribe(() => {
      if (!DateUtils.isSameDate(this.dateLastRefresh, DateUtils.today)) {
        this.dateLastRefresh = DateUtils.today;
        this.navigateToActualDate();
      }
    });
  }

  navigateTo(date: Date) {
    const month = this.currentMonth
    if (!DateUtils.isInMonth(month, date)) {
      this._currentMonth$.next(date)
    }

    const day = this.currentDay
    if (!DateUtils.isSameDate(day, date)) {
      this._currentDay$.next(date)
    }
  }

  navigateToActualDate() {
    this.navigateTo(DateUtils.today)
  }

}
