import {Injectable} from '@angular/core';
import {AddedTopicsAndTimeTypesService} from './added-topics-and-time-types.service';
import {MergedTopic, MergedTopicsService} from './merged-topics.service';
import {TimeEntryTypeService} from './repository/time-entry-type.service';
import {SmartAddingInputComponent} from '../shared-components/smart-adding-input/smart-adding-input.component';
import {Async} from '../util/async';
import {TopicService} from './repository/topic.service';
import {ImageService} from './repository/image.service';

@Injectable({
  providedIn: 'root'
})
export class SmartInputOptionService {

  private basedataCacheReady = false;

  constructor(private addedTopicsAndTimeTypesService: AddedTopicsAndTimeTypesService,
              private mergedTopicsService: MergedTopicsService,
              private topicService: TopicService,
              private timeEntryTypeService: TimeEntryTypeService,
              private imageService: ImageService) {
    mergedTopicsService.mergedBasedata$.subscribe(mergedBasedata => {
      this.basedataCacheReady = mergedBasedata != null;
    });
  }


  async findActionsForInput(inputComponent: SmartAddingInputComponent, value: string, actionRequired = true , excludeTopicIds: number[] = []): Promise<SmartInputOption[]> {
    await Async.sleepUntil(() => this.basedataCacheReady);

    const actions: SmartInputOption[] = [];
    if (actionRequired === true) {
      await this.addProjectToAddActions(actions, value);
    } else {
      await this.filterTopics(actions, value , excludeTopicIds);
    }
    return this.sort(actions);
  }


  private async generateSmartInputOption(themaMerged, actionRequired = true): Promise<SmartInputOption> {
    const customerImage: any = await this.imageService.getDecodedImage(themaMerged.customer.icon);
    return {
      label: `${themaMerged.customer.name} / ${themaMerged.assignment.name}`,
      subLabel: `${themaMerged.topic.name}`,
      topicId: themaMerged.topic.id,
      icon: customerImage,
      tooltip: themaMerged.topic.description != '' ? `${themaMerged.topic.description}` : `${themaMerged.customer.name} / ${themaMerged.assignment.name} / ${themaMerged.topic.name}`,
      actionFunction: actionRequired ? () => this.addedTopicsAndTimeTypesService.addTopic(themaMerged.topic.id) : () => {
      }
    };
  }

  private async filterTopics(actions: SmartInputOption[], value: string, excludeTopicIds: number[]) {
    const mergedTopics: MergedTopic[] = this.mergedTopicsService.mergedBasedata;
    return Promise.all(
      mergedTopics
        .filter(mergedTopic =>
          !this.topicService.isTopicArchived(mergedTopic.topic) &&
          this.valueMatchesMergedTopic(mergedTopic, value) && !excludeTopicIds.includes(mergedTopic.topic.id)
        )
        .map(async mergedTopic => {
          actions.push(await this.generateSmartInputOption(mergedTopic, false));
        })
    );
  }

  private async addProjectToAddActions(actions: SmartInputOption[], value: string) {
    const mergedTopics: MergedTopic[] = this.mergedTopicsService.mergedBasedata;
    return Promise.all(
      mergedTopics
        .filter(mergedTopic =>
          !this.topicService.isTopicArchivedInCurrentMonth(mergedTopic.topic) &&
          this.valueMatchesMergedTopic(mergedTopic, value) &&
          !this.addedTopicsAndTimeTypesService.containsTopic(mergedTopic.topic.id)
        )
        .map(async mergedTopic => {
          actions.push(await this.generateSmartInputOption(mergedTopic));
        })
    );
  }

  private valueMatchesMergedTopic(themaMerged: MergedTopic, value: string) {
    const values = value.split(' ');
    if (!value.toLowerCase) {
      return false;
    }
    return values.reduce((acc, currentValue) => {
      return acc &&
        (themaMerged.topic.name.toLowerCase().includes(currentValue.toLowerCase())
          || themaMerged.assignment.name.toLowerCase().includes(currentValue.toLowerCase())
          || themaMerged.customer.name.toLowerCase().includes(currentValue.toLowerCase()));
    }, true);
  }

  private sort(actions: SmartInputOption[]) {
    return actions.sort((a, b) => {
      return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
    });
  }
}


export interface SmartInputOption {
  label: string;
  subLabel: string;
  topicId: number;
  icon: string;
  tooltip: string;
  actionFunction?: () => void;
  replaceInputValue?: string;
}
