import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {startWith, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';
import {SmartInputOption, SmartInputOptionService} from '../../services/smart-input-option.service';
import {MatAutocomplete} from '@angular/material/autocomplete';

@Component({
  selector: 'app-smart-adding-input',
  templateUrl: './smart-adding-input.component.html',
  styleUrls: ['./smart-adding-input.component.scss']
})
export class SmartAddingInputComponent implements OnInit {

  @ViewChild('input') inputRef: ElementRef;
  @Input() isSubmitted = undefined;
  @Input() actionRequired = true;
  @Input() excludeTopicIds: number[]  = [];
  @Output() selectedTopic =  new EventEmitter<SmartInputOption>();
  @ViewChild(MatAutocomplete) matAutocomplete: MatAutocomplete;
  autocompleteFormControl = new UntypedFormControl();
  availableOptions: Observable<SmartInputOption[]>;

  constructor(private smartInputOptionService: SmartInputOptionService) {
  }

  ngOnInit(): void {
    this.availableOptions = this.autocompleteFormControl.valueChanges
      .pipe(
        startWith(''),
        switchMap(value => {
            return this.smartInputOptionService.findActionsForInput(this, value ?? '', this.actionRequired, this.excludeTopicIds);
          }
        )
      );
    }

  hoverOption(index): void {
    this.matAutocomplete._keyManager.setActiveItem(index);
  }

  optionSelected(): void {
    const selectedValue = this.autocompleteFormControl.value as SmartInputOption;
    selectedValue.actionFunction();
    this.selectedTopic.emit(selectedValue);
    this.autocompleteFormControl.setValue('');
    this.inputRef.nativeElement.blur();
  }

  updateActions(): void {
    const val = this.autocompleteFormControl.value;
    this.autocompleteFormControl.setValue((val !== undefined) ? val : '', {
      onlySelf: false,
      emitEvent: true,
      emitModelToViewChange: false,
      emitViewToModelChange: true
    });
  }
}

