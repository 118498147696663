export class ViewUtils {

  static getViewportSize(): Size {
    // This works for all browsers except IE8 and before
    if (window.innerWidth != null) {
      return {width: window.innerWidth, height: window.innerHeight}
    }

    // For IE (or any browser) in Standard mode
    if (window.document.compatMode == 'CSS1Compat') {
      return {
        width: window.document.documentElement.clientWidth,
        height: window.document.documentElement.clientHeight
      }
    }

    // For browsers in Quirks mode
    return {width: window.document.body.clientWidth, height: window.document.body.clientHeight}
  }

  static rotateAxisRight(rect: Size | Rect): Rect {
    return {width: rect.height, height: rect.width, top: (<Rect>rect).left, left: (<Rect>rect).top}
  }

  static rotateAxisLeft(rect: Size | Rect): Rect {
    const result = {width: 0, height: 0, top: 0, left: 0}
    result.width = rect.height
    result.height = rect.width

    if ((<Rect>rect).top && (<Rect>rect).left) {
      result.top = (<Rect>rect).left - rect.width
      result.left = (<Rect>rect).top
    }
    return result
  }

  static flipAxis(rect: Size | Rect): Rect {
    return undefined
  }
}

export interface Size {
  width: number,
  height: number
}

export interface Rect {
  width: number,
  height: number,
  top: number,
  left: number

}
