import {Injectable} from '@angular/core'
import {UserService} from './repository/user.service'
import {Async} from '../util/async'

@Injectable({providedIn: 'root'})
export class LocalUserPropertiesService {

  constructor(private userService: UserService) {
  }

  async setProperty(identifier: string, value: any) {
    const key = await this.generateKey(identifier)
    const data: StorageData = {data: value}
    return window.localStorage.setItem(key, JSON.stringify(data))
  }

  async deleteProperty(identifier: string) {
    const key = await this.generateKey(identifier)
    return window.localStorage.removeItem(key)
  }

  async getProperty(identifier: string, defaultValue: any) {
    const key = await this.generateKey(identifier)
    const data = window.localStorage.getItem(key)
    if (data == null) {
      return defaultValue
    } else {
      const value = (JSON.parse(data) as StorageData).data
      return value
    }
  }

  private async generateKey(identifier: string) {
    await Async.sleepUntil(() => this.userService.me != null)
    return `UP_${this.userService.me.id}_${identifier}`
  }

}

interface StorageData {
  data: any
}
