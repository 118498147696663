import {Injectable} from '@angular/core'
import {TopicEntity, TopicService} from './repository/topic.service'
import {AssignmentEntity, AssignmentService} from './repository/assignment.service'
import {CustomerEntity, CustomerService} from './repository/customer.service'
import {BehaviorSubject, Observable} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class MergedTopicsService {

  private allData: AllTopicsAssignmentsCustomers = {
    topics: null,
    assignments: null,
    customers: null
  }

  constructor(private customerService: CustomerService,
              private assignmentService: AssignmentService,
              private topicService: TopicService) {
    this.initializeSubscriptions()
  }

  private _mergedBasedata$ = new BehaviorSubject<MergedTopic[]>(null)

  get mergedBasedata$(): Observable<MergedTopic[]> {
    return this._mergedBasedata$
  }

  get mergedBasedata(): MergedTopic[] {
    return this._mergedBasedata$.getValue()
  }

  private initializeSubscriptions() {
    this.topicService.all$.subscribe(topics => {
      this.allData.topics = topics
      this.mergeBasedata()
    })
    this.assignmentService.all$.subscribe(assignments => {
      this.allData.assignments = assignments
      this.mergeBasedata()
    })
    this.customerService.all$.subscribe(customers => {
      this.allData.customers = customers
      this.mergeBasedata()
    })
  }

  private allDataLoaded(): boolean {
    return this.allData.assignments != null && this.allData.customers != null && this.allData.topics != null
  }

  private mergeBasedata() {
    if (this.allDataLoaded()) {
      const mergedBasedata: MergedTopic[] = []

      this.allData.topics
        .forEach(topic => {
          const assignment = this.allData.assignments.filter(v => v.id == topic.assignmentFK)[0]
          if (assignment) {
            const customer = this.allData.customers.filter(k => k.id == assignment.customFK)[0]
            if (customer) {
              mergedBasedata.push({
                customer: customer,
                assignment: assignment,
                topic: topic
              })
            }
          }
        })

      this._mergedBasedata$.next(mergedBasedata)
    }
  }
}

export interface MergedTopic {
  topic: TopicEntity,
  assignment: AssignmentEntity,
  customer: CustomerEntity
}

interface AllTopicsAssignmentsCustomers {
  topics: TopicEntity[],
  assignments: AssignmentEntity[],
  customers: CustomerEntity[]
}
