import {Injectable} from '@angular/core'
import {BehaviorSubject, Observable, Subscription} from 'rxjs'
import {HttpClient} from '@angular/common/http'
import {GraphqlCollectorService} from '../http/graphql-collector.service'
import {SessionService} from '../session.service'
import {environment} from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class TimeEntryTypeService {
  private repoURL: string

  constructor(private httpClient: HttpClient,
              private graphQlService: GraphqlCollectorService,
              private sessionService: SessionService
      ) {
    this.repoURL = `${environment.serverUrl}/timeEntryTypes`
    this.sessionService.loginData$.subscribe(loginData => {
      if (loginData.isValid) {
        this.requestAll()
      }
    })
  }

  private _all$ = new BehaviorSubject<TimeEntryTypeEntity[]>([])

  get all$(): Observable<TimeEntryTypeEntity[]> {
    return this._all$
  }

  get all(): TimeEntryTypeEntity[] {
    return this._all$.getValue()
  }

  static allGraphsQLQuery() {
    return {
      variables: [],
      function: 'timeEntryTypes',
      fieldBody: TIME_ENTRY_TYPE_TEMPLATE
    }
  }

  // TODO Has yet to be tested
  async getAllAsPromise(): Promise<TimeEntryTypeEntity[]> {
    const resolver = (subsription: Subscription, resolve: Function, result: TimeEntryTypeEntity[]) => {
      resolve(result)
      subsription.unsubscribe()
    }
    const rejecter = (subsription: Subscription, reject: Function, error: any) => {
      reject(error)
      subsription.unsubscribe()
    }

    return new Promise<TimeEntryTypeEntity[]>((resolve, reject) => {
      const subscription = this.all$.subscribe(
        result => {
          if (result != undefined) {
            resolver(subscription, resolve, result)
          }
        },
        error => {
          rejecter(subscription, reject, error)
        }
      )
    })
  }

  requestAll() {
    this.graphQlService.query(TimeEntryTypeService.allGraphsQLQuery()).subscribe(timeEntryTypes => this._all$.next(timeEntryTypes))
  }

  /* APIs below are not yet converted to BloC pattern */

  get(id: number) {
    return this.all.find(value => value.id == id)
  }

  getTypeEntitiesByType(type: Types): Map<number, TimeEntryTypeEntity> {
    const types = this.all
    const filteredtypes = new Map<number, TimeEntryTypeEntity>()
    if (types != undefined) {
      types.filter(value => {
        if (value.type == type) {
          filteredtypes.set(value.id, value)
        }
      })
    }
    return filteredtypes

  }

  update(timeEntryType: TimeEntryTypeEntity) {
    this.httpClient.put(`${this.repoURL}/${timeEntryType.id}`, timeEntryType).subscribe(() => this.requestAll())
  }

  create(timeEntryType: TimeEntryTypeEntity) {
    this.httpClient.post(this.repoURL, timeEntryType).subscribe(() => this.requestAll())
  }

  delete(timeEntryType: TimeEntryTypeEntity) {
    timeEntryType.archived = true
    this.update(timeEntryType)
  }

  restore(timeEntryType: TimeEntryTypeEntity) {
    timeEntryType.archived = false
    this.update(timeEntryType)
  }


}

export interface TimeEntryTypeEntity {
  id: number
  name: string
  description: string
  type: Types
  archived: boolean
  ratio: number
}

export enum Types {
  regular = 'Regular',
  specialActive = 'SpecialActive',
  specialPassive = 'SpecialPassive',
}

export enum TypeNames {
  regular = 'Standard',
  specialActive = 'Sonderzeit Aktiv',
  specialPassive = 'Sonderzeit Passiv',
}


export const TIME_ENTRY_TYPE_TEMPLATE =
  `
    id,
    name,
    description,
    type,
    archived,
    ratio
 `

