import {Injectable} from '@angular/core'
import {TimeNavigationService} from './time-navigation.service'
import {Observable, Subject} from 'rxjs'
import {LocalTimeEntriesService} from './repository/local-time-entries.service'
import {DateUtils, isSameMonthYear, MonthYear, toMonthYear} from '../util/date-utils'
import {SessionService} from './session.service'
import {KeyValue, KeyValueService} from './repository/key-value.service'

const ADDED_TOPICS_ORDER_KEY = 'wtb_added_topics_order'
export const REGULAR_TIME_ENTRY_TYPE_ID = 1

@Injectable({
  providedIn: 'root'
})
export class AddedTopicsAndTimeTypesService {

  private topicAndTimeEntryTypes: TopicType[] = []

  constructor(private timeNavigationService: TimeNavigationService,
              private localTimeEntriesService: LocalTimeEntriesService,
              private sessionService: SessionService,
              private keyValueService: KeyValueService) {

    this.keyValueService.all$.subscribe(keyValues => {
      if (keyValues != undefined) {
        const keyValue = keyValues.find(val => val.key == ADDED_TOPICS_ORDER_KEY)
        if (keyValue) {
          this.topicAndTimeEntryTypes = JSON.parse(keyValue.value)
          this._addedTopicsAndTimeEntryTypes$.next(this.topicAndTimeEntryTypes)
        }
        this.registerAutoupdateWhenNewCurrentMonthValuesArrive()
      }
    })

    sessionService.onLogout$.subscribe(() => {
      this.topicAndTimeEntryTypes = []
    })
  }

  private _addedTopicsAndTimeEntryTypes$ = new Subject<TopicType[]>()

  get addedTopicsAndTimeEntryTypes$(): Observable<TopicType[]> {
    return this._addedTopicsAndTimeEntryTypes$
  }

  get addedTopicsAndTimeEntryTypes(): TopicType[] {
    return this.topicAndTimeEntryTypes
  }

  moveTopic(topicId: number, date: Date, shift: number) {
    if (shift === 0) {
      return
    }
    this.sortTopicsDescendingByMonthYear()
    const topic = this.topicAndTimeEntryTypes.find(value => value.topicId === topicId && isSameMonthYear(value.monthYear, toMonthYear(date)))
    const topicIndex = this.topicAndTimeEntryTypes.indexOf(topic)
    this.topicAndTimeEntryTypes.splice(topicIndex, 1)
    this.topicAndTimeEntryTypes.splice(topicIndex + shift, 0, topic)
    this.updateAddedTopicsAndTimeEntryTypes()
    this.sortTopicsDescendingByMonthYear()
  }

  updateAddedTopicsAndTimeEntryTypes() {
    const topicsJSON = JSON.stringify(this.topicAndTimeEntryTypes)
    this.keyValueService.update({
      key: ADDED_TOPICS_ORDER_KEY,
      value: topicsJSON
    } as KeyValue)

    this._addedTopicsAndTimeEntryTypes$.next(this.topicAndTimeEntryTypes)
  }

  addTopic(topicId: number, date?: Date): TopicType {
    const monthYear = toMonthYear(this.getActualMonth(date))
    const addResult = this.internalAddTopic(topicId, monthYear)

    if (addResult.isNew) {
      this.updateAddedTopicsAndTimeEntryTypes()
    }

    return addResult.topic
  }

  addTopicsAndTimeEntryTypes(topics: TopicType[]) {
    let delegateChanges = false
    topics.forEach(topic => {
      const addTopicResult = this.internalAddTopic(topic.topicId, topic.monthYear)
      delegateChanges = delegateChanges || addTopicResult.isNew

      topic.timeEntryTypeIds.forEach(timeEntryTypeId => {
        const addTimeEntryTypeResult = this.internalAddTimeEntryType(timeEntryTypeId, topic.topicId, topic.monthYear)
        delegateChanges = delegateChanges || addTimeEntryTypeResult
      })
    })

    if (delegateChanges) {
      this.updateAddedTopicsAndTimeEntryTypes()
    }
  }

  removeTopic(topicId: number, date?: Date) {
    const month = (date != null) ? date : this.timeNavigationService.currentMonth
    this.localTimeEntriesService.removeAllByPredicate((entry) => {
      return entry.topicId == topicId && DateUtils.isInMonth(entry.date, month)
    })

    const filteredTopicTypes = this.topicAndTimeEntryTypes
      .filter(value => value.topicId != topicId || !isSameMonthYear(value.monthYear, toMonthYear(month)))

    if (filteredTopicTypes.length != this.topicAndTimeEntryTypes.length) {
      this.topicAndTimeEntryTypes = filteredTopicTypes
      this.updateAddedTopicsAndTimeEntryTypes()
    }
  }

  containsTopic(topicId: number, date?: Date): boolean {
    return this.internalContainsTopic(topicId, toMonthYear(this.getActualMonth(date)))
  }

  private internalContainsTopic(topicId: number, monthYear: MonthYear): boolean {
    return this.getTopicType(topicId, monthYear) != undefined
  }

  addTimeEntryType(timeEntryTypeId: number, topicId: number, date?: Date) {
    if (this.internalAddTimeEntryType(timeEntryTypeId, topicId, toMonthYear(this.getActualMonth(date)))) {
      this.updateAddedTopicsAndTimeEntryTypes()
    }
  }

  removeTimeEntryType(timeEntryTypeId: number, topicId: number, date?: Date) {
    if (timeEntryTypeId === REGULAR_TIME_ENTRY_TYPE_ID) {
      // we cannot have a topic without a regular time entry type
      return
    }
    const month = (date != null) ? date : this.timeNavigationService.currentMonth
    this.localTimeEntriesService.removeAllByPredicate(entry => {
      return entry.topicId == topicId && entry.timeEntryTypeId == timeEntryTypeId && DateUtils.isInMonth(entry.date, month)
    })
    const topicType = this.getTopicType(topicId, toMonthYear(month))
    if (topicType != undefined) {
      const filteredData = topicType.timeEntryTypeIds.filter(id => id != timeEntryTypeId)

      if (filteredData.length != topicType.timeEntryTypeIds.length) {
        topicType.timeEntryTypeIds = filteredData
        this.updateAddedTopicsAndTimeEntryTypes()
      }
    }
  }

  containsTimeEntryType(timeEntryTypeId: number, topicId?: number, date?: Date) {
    const topicType = this.getTopicType(topicId, toMonthYear(this.getActualMonth(date)))
    if (topicType == undefined) {
      return false
    }
    return topicType.timeEntryTypeIds.some(id => id == timeEntryTypeId)
  }

  private internalAddTopic(topicId: number, monthYear: MonthYear): { topic: TopicType, isNew: boolean } {
    const topic = this.getTopicType(topicId, monthYear)
    if (topic != undefined) {
      return {
        topic: topic,
        isNew: false
      }
    }

    const newType: TopicType = {
      topicId: topicId,
      timeEntryTypeIds: (topicId) ? [REGULAR_TIME_ENTRY_TYPE_ID] : [],
      monthYear: monthYear
    }

    this.topicAndTimeEntryTypes.unshift(newType)
    this.sortTopicsDescendingByMonthYear()

    return {
      topic: newType,
      isNew: true
    }
  }

  private internalAddTimeEntryType(timeEntryTypeId: number, topicId: number, monthYear: MonthYear): boolean {
    const topicType = this.internalAddTopic(topicId, monthYear).topic
    if (!topicType.timeEntryTypeIds.find(addedTimeEntryTypeId => addedTimeEntryTypeId == timeEntryTypeId)) {
      topicType.timeEntryTypeIds.push(timeEntryTypeId)
      return true
    }
    return false
  }

  private sortTopicsDescendingByMonthYear() {
    this.addedTopicsAndTimeEntryTypes.sort((el1: TopicType, el2: TopicType) => {
      return (el2.monthYear.year - el1.monthYear.year) * 100 + (el2.monthYear.month - el1.monthYear.month)
    })
  }

  private registerAutoupdateWhenNewCurrentMonthValuesArrive() {
    this.localTimeEntriesService.currentMonth$.subscribe(entries => {
      if (entries != undefined) {
        entries
          .forEach(entry => {
            if (entry.topicId != null && entry.timeEntryTypeId != null) {
              this.addTopic(entry.topicId, entry.date)
            }
            if (entry.timeEntryTypeId != null) {
              this.addTimeEntryType(entry.timeEntryTypeId, entry.topicId, entry.date)
            }
          })
      }
    })
  }

  private getTopicType(topicId: number, monthYear?: MonthYear) {
    const evaluatedMonthYear = (monthYear == null) ? this.getCurrentMonthAsMonthYear() : monthYear
    return this.topicAndTimeEntryTypes
      .find(topicType => topicType.topicId == topicId && isSameMonthYear(topicType.monthYear, evaluatedMonthYear))
  }

  private getCurrentMonthAsMonthYear() {
    return toMonthYear(this.timeNavigationService.currentMonth)
  }

  private getActualMonth(date?: Date): Date {
    return (date != null) ? date : this.timeNavigationService.currentMonth
  }
}

export interface TopicType {
  topicId?: number
  timeEntryTypeIds: number[]
  monthYear: MonthYear
}
