import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {TimeNavigationService} from '../time-navigation.service'
import {DateUtils} from '../../util/date-utils'
import {GraphqlCollectorService} from '../http/graphql-collector.service'
import {createVariable, GraphQLQuery} from '../../util/graphql-executor'
import {
  ModificationResult,
  splitMutationResult,
  TimeEntryDeleteBulkPayload,
  TimeEntryDeleteResult,
  TimeEntryMutationError,
  TimeEntryMutationResult,
  TimeEntryUpsertBulkPayload,
  TimeEntryUpsertResult
} from './graphql/graphql-types'
import {FetchedMonthSubmittedData, FetchedMonthsubmittedDataService} from '../fetched-monthsubmitted-data.service'
import {map, take} from 'rxjs/operators'
import {environment} from '../../../environments/environment'

const ME = '0'

@Injectable({
  providedIn: 'root'
})
export class TimeEntriesService {
  private repoURL: string
  private repoURLMe: string

  constructor(private graphQLService: GraphqlCollectorService,
              private timeNavigationService: TimeNavigationService,
              private fetchedMonthSubmittedDataService: FetchedMonthsubmittedDataService,
              private httpClient: HttpClient
  ) {
    this.repoURL = `${environment.serverUrl}/timeEntries`
    this.repoURLMe = `${environment.serverUrl}/me`
  }

  static allUnsubmittedTimeEntriesQuery(userId: string, forceFetchMonths: string[]) {
    return {
      function: 'monthsSubmittedAll',
      variables: [
        createVariable('userId', 'Int', userId),
        createVariable('submitTypes', '[SubmitType!]', ['Open', 'Reopened']),
        createVariable('forceFetchMonths', '[String!]', forceFetchMonths)
      ],
      fieldBody: MONTHS_SUBMITTED_TIME_ENTRY_ENTITY_TEMPLATE
    }
  }

  private static timeInsertUpdateMutation(entities: TimeEntryEntity[]) {
    return {
      function: 'timeEntriesBulk',
      variables: [
        createVariable('input', 'TimeEntryUpsertBulkInput!', {
          timeEntries: entities
        })
      ],
      fieldBody: TIME_ENTRY_ENTITY_BULK_TEMPLATE
    }
  }

  private static timeDeleteMutation(entities: TimeEntryEntity[]): GraphQLQuery {
    return {
      function: 'timeEntriesBulkDelete',
      variables: [
        createVariable('input', 'TimeEntryBulkDeleteInput!', {
          uuids: entities.map(entity => entity.id)
        })
      ],
      fieldBody: TIME_ENTRY_ENTITY_BULK_DELETE_TEMPLATE
    }
  }

  async fetchAllUnsubmittedTimeEntries(): Promise<TimeEntryEntity[]> {
    return new Promise<TimeEntryEntity[]>(resolve => {
      const firstDayOfCurrentMonth = DateUtils.getFirstDayOfMonth(this.timeNavigationService.currentMonth)
      const previousMonth = DateUtils.getPreviousMonth(firstDayOfCurrentMonth)
      const nextMonth = DateUtils.getNextMonth(firstDayOfCurrentMonth)

      const forceFetchMonths = [
        DateUtils.dateToString(previousMonth),
        DateUtils.dateToString(firstDayOfCurrentMonth),
        DateUtils.dateToString(nextMonth),
      ]

      this.graphQLService.query(TimeEntriesService.allUnsubmittedTimeEntriesQuery(ME, forceFetchMonths)).subscribe(results => {
        const fetchedTimeEntryEntities = results.reduce((timeEntryEntities: TimeEntryEntity[], month) => {
          return timeEntryEntities.concat(month.timeEntries)
        }, [] as TimeEntryEntity[])

        resolve(fetchedTimeEntryEntities)
      }, error => {
        // Handle error
        console.log('Error fetching months:', error)
      })
    })
  }

  async pushEntitiesToRemote(entities: TimeEntryEntity[]): Promise<TimeEntryMutationResult> {
    const isValidTimeEntry = (entity: TimeEntryEntity) => entity.gross > 0
    const bulks: TimeEntryEntity[][] = []
    const insertUpdateBulk: TimeEntryEntity[] = []
    const deleteBulk: TimeEntryEntity[] = []

    bulks.push(deleteBulk)
    bulks.push(insertUpdateBulk)

    entities.forEach(entity => {
      const index: number = +isValidTimeEntry(entity)
      bulks[index].push(entity)
    })

    return new Promise<TimeEntryMutationResult>(resolve => {
        return Promise.all([
          this.bulkOperation(insertUpdateBulk, TimeEntriesService.timeInsertUpdateMutation),
          this.bulkOperation(deleteBulk, TimeEntriesService.timeDeleteMutation)
        ]).then((allResult) => {
            const insertUpdateResult = splitMutationResult<TimeEntryUpsertResult, TimeEntryMutationError>(allResult[0].result)

            const insertUpdateValidResults = insertUpdateResult.objects
            const insertUpdateErrors = insertUpdateResult.errors

            const deleteResult = splitMutationResult<TimeEntryDeleteResult, TimeEntryMutationError>(allResult[1].result)
            const errors = insertUpdateErrors.concat(deleteResult.errors)

            insertUpdateValidResults
              .filter((data) => {
                return data.monthSubmittedId != null
              }).forEach((data) => {
              this.fetchedMonthSubmittedDataService.delegateFetchedMonthSubmittedData({
                monthId: data.monthSubmittedId,
                month: data.month
              } as FetchedMonthSubmittedData)
            })

            resolve({
              result: (errors.length === 0) ? ModificationResult.SUCCESS : ModificationResult.WARNING,
              errors: errors
            })
          },
          () => {
            resolve({
              result: ModificationResult.ERROR,
              errors: undefined
            })
          })
      }
    )
  }

  private async bulkOperation(bulk: TimeEntryEntity[], op: (bulk: TimeEntryEntity[]) => GraphQLQuery): Promise<TimeEntryUpsertBulkPayload | TimeEntryDeleteBulkPayload> {
    if (bulk.length !== 0) {
      return this.graphQLService.mutation(
        op(bulk)
      ).pipe(
        take(1)
      ).toPromise()
    }

    return {
      result: []
    }
  }

  put(entity: TimeEntryEntity): Promise<ModificationResult> {
    return this.httpClient
      .put(this.repoURLMe + '/' + entity.id, entity, {observe: 'response'})
      .pipe(
        map((response) => {
          this.fetchedMonthSubmittedDataService.evaluateResponseHeaders(response.headers, entity.date)
          return ModificationResult.SUCCESS
        }, () => {
          return ModificationResult.ERROR
        })
      ).toPromise()
  }

  delete(entryId: string): Promise<ModificationResult> {
    return this.httpClient
      .delete(`${this.repoURL}/${entryId}`, {observe: 'response'})
      .pipe(
        map((response) => {
          this.fetchedMonthSubmittedDataService.evaluateResponseHeaders(response.headers, null)
          return ModificationResult.SUCCESS
        }, () => {
          return ModificationResult.ERROR
        })
      ).toPromise()
  }

  private async bulkPut(bulk: TimeEntryEntity[]) {
    if (bulk.length !== 0) {
      return await new Promise<FetchedMonthSubmittedData[]>(resolve => {
        this.graphQLService.mutation(TimeEntriesService.timeInsertUpdateMutation(bulk)).subscribe((dataArray) => {
          const fetchedMonth = dataArray.map(data => {
            return {
              monthId: data.monthSubmittedId,
              month: data.month
            } as FetchedMonthSubmittedData
          })
          resolve(fetchedMonth)
        })
      })
    }

    return []
  }

  private async bulkDelete(bulk: TimeEntryEntity[]) {
    if (bulk.length !== 0) {
      await this.graphQLService.mutation(TimeEntriesService.timeDeleteMutation(bulk))
    }
  }

}


export interface TimeEntryEntity {
  id?: string
  topicFK: number
  timeEntryTypeFK: number
  userFK: number
  date: string
  gross: number
  net: number
}


export const TIME_ENTRY_ENTITY_TEMPLATE =
  `
    id,
    monthsSubmittedFK,
    topicFK,
    timeEntryTypeFK,
    userFK,
    date,
    gross,
    net
 `

const MONTHS_SUBMITTED_TIME_ENTRY_ENTITY_TEMPLATE =
  `
    month,
    timeEntries {
      ${TIME_ENTRY_ENTITY_TEMPLATE}
    }
  `

const TIME_ENTRY_ENTITY_BULK_TEMPLATE =
  `
  result {
      valueOrError {
        ... on TimeEntryUpsertResult {
          id
          monthSubmittedId
          month
        }
        ... on TimeEntryMutationError {
          id
          status
          message
        }
      }
    }
 `

const TIME_ENTRY_ENTITY_BULK_DELETE_TEMPLATE =
  `
    result {
      valueOrError {
        ... on TimeEntryDeleteResult {
          id
        }
        ... on TimeEntryMutationError {
          id
          status
        }
      }
    }
 `
