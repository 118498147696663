<button #button
        (click)="toggleOptions()"
        (dialogClosed)="onDialogClose($event)"
        (dialogOpened)="onDialogOpen()"
        [appTooltipDialog]="dialogComponent"
        [autoFocus]="false"
        [dialogData]="dialogData"
        [dialogMargin]="0"
        class="select-container"
        dialogId="dropdown__tooltip-dialog"
        dialogPosition="bottom"
        mat-icon-button>
  <div [class.placeholder]="!labelInputData.from" class="label">
    {{ (showSelectAllButton && !labelInputData.from) ? 'Gesamter Zeitraum' : (labelInputData | getLabel:getLabel) }}
  </div>
  <mat-icon *ngIf="!open">
    keyboard_arrow_down
  </mat-icon>
  <mat-icon *ngIf="open">
    keyboard_arrow_up
  </mat-icon>
</button>
