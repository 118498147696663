import {Component, EventEmitter, Input, Output} from '@angular/core'

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss']
})
export class PagerComponent {

  @Input() numberOfPages: number
  @Input() showNumberOfPages: boolean

  @Output() currentPage = new EventEmitter<number>()
  currentValue = 1

  navigate(deltaPage: number): void {
    const navigateTo = this.currentValue + deltaPage
    this.currentValue = Math.max(Math.min(navigateTo, this.numberOfPages), 1)
    this.currentPage.emit(this.currentValue)
  }
}
