import {Component, Input} from '@angular/core'

@Component({
  selector: 'app-speech-bubble',
  templateUrl: 'speech-bubble.component.html',
  styleUrls: ['speech-bubble.component.scss']

})
export class SpeechBubbleComponent {

  @Input() arrowPosition = 'left'
  @Input() error: boolean = false

}
