export class Async {

  static async sleep(timeInMillis: number, scaleFactor: number = 1.0): Promise<void> {
    const factoredTime = scaleFactor * timeInMillis;
    return await new Promise(((resolve) => window.setTimeout(resolve, factoredTime)));
  }

  static async delay<T>(action: () => Promise<T>, timeInMillis: number, scaleFactor: number = 1.0): Promise<T> {
    const factoredTime = scaleFactor * timeInMillis;
    return await new Promise<T>(((resolve) => window.setTimeout(() => {
      const result: Promise<T> = action();
      resolve(result);
    }, factoredTime)));
  }


  static async sleepUntil(predicate: () => boolean, timeout: number = 5000, sleepTime: number = 100): Promise<void> {
    const start = Date.now();
    while (!predicate()) {
      if ((Date.now() - start) > timeout) {
        throw new Error(`wait for ${predicate} timed out after ${timeout}ms`);
      }
      await Async.sleep(sleepTime);
    }
  }

}
