import {Injectable, OnInit} from '@angular/core'
import {BehaviorSubject, Observable, Subscription} from 'rxjs'
import {TimeNavigationService} from '../time-navigation.service'
import {SessionService} from '../session.service'
import {User, UserService} from '../repository/user.service'
import {LocalAbsenceEntriesService} from '../repository/local-absence-entries.service'
import {LocalTimeEntriesService} from '../repository/local-time-entries.service'
import {ModifiableDataObject, Synchronizer} from '../../util/synchronizer'
import {LocalSubmitMonthAllUsersService} from '../repository/local-submit-month-all-users.service'
import {first, skip} from 'rxjs/operators'
import {Bouncer} from '../../util/bouncer'
import {Router, NavigationEnd} from '@angular/router'

interface SynchronizerProperties {
  forceSync: boolean
  repeatAfterSync: boolean
  modifiedSubscription: Subscription
  shouldSynchronize: boolean
}

const TIME_BASE_AUTO_RESYNC_IN_MILLIS = 3000

@Injectable({
  providedIn: 'root'
})
export class SynchronisationService {

  private synchronizers = new Map<Synchronizer<ModifiableDataObject>, SynchronizerProperties>()

  private syncAllbouncer = new Bouncer(500)
  private syncBouncer = new Bouncer(TIME_BASE_AUTO_RESYNC_IN_MILLIS)
  private isVerifyPageVisited = false
  private firstSync = true

  constructor(private timeNavigationService: TimeNavigationService,
              private userService: UserService,
              private sessionService: SessionService,
              private localAbsenceEntriesService: LocalAbsenceEntriesService,
              private localTimeEntriesService: LocalTimeEntriesService,
              private localSubmitMonthAllUsersService: LocalSubmitMonthAllUsersService,
              private router: Router) {

    sessionService.onLogout$.subscribe(() => {
      this.synchronizers.forEach((value, key) => this.unregister(key))
    })

    sessionService.addLogoutInterceptor(async () => {
      await this.status$
        .pipe(
          first((status) => {
            return status == SynchronizationStatus.IS_SYNCHRONIZED
          })
        ).toPromise()

      return true
    })

    userService.me$.subscribe(me => {
      if (me != undefined && userService.hasApplicationAccess(me)) {
        this.register(localAbsenceEntriesService, me)
        this.register(localTimeEntriesService, me)
        this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd && event.url === '/verify' && !this.isVerifyPageVisited) {
            this.register(localSubmitMonthAllUsersService, me)
            this.isVerifyPageVisited = true
            if (!this.firstSync) {
              this.synchronizeSingleSynchronizer(localSubmitMonthAllUsersService).then()
            }
          }
        })
        this.synchronizeAll().then(() => this.firstSync = false)
        this.timeNavigationService.currentMonth$.pipe(
          skip(1)
        ).subscribe((month) => {
          this.synchronizeAllOnMonthChange().then()
        })
      }
    })
  }

  private _status$ = new BehaviorSubject<SynchronizationStatus>(SynchronizationStatus.IS_SYNCHRONIZED)

  get status$(): Observable<SynchronizationStatus> {
    return this._status$
  }

  get status(): SynchronizationStatus {
    return this._status$.getValue()
  }

  register<T extends ModifiableDataObject>(synchronizer: Synchronizer<T>, user: User) {
    if (synchronizer.isAvailableFor(user) && !this.synchronizers.has(synchronizer)) {
      const subscription = synchronizer.modified$.subscribe((forceSync?: boolean) => {
        this.initializeSynchronization(synchronizer, forceSync)
      })

      this.synchronizers.set(synchronizer, {
        repeatAfterSync: false,
        modifiedSubscription: subscription,
        shouldSynchronize: false,
        forceSync: false
      })
    }
  }

  unregister<T extends ModifiableDataObject>(synchronizer: Synchronizer<T>) {
    const synchronizerProperties = this.getSynchronizerProperties(synchronizer)

    if (synchronizerProperties.modifiedSubscription != undefined) {
      synchronizerProperties.modifiedSubscription.unsubscribe()
      this.synchronizers.delete(synchronizer)
    }
  }

  anySynchronizersCurrentlySynchronizing(): boolean {
    const synchronizerIterator = this.synchronizers.keys()

    let iteratorResult: IteratorResult<any> = synchronizerIterator.next()
    do {
      if (iteratorResult.value.isCurrentlySynchronizing) {
        return true
      }
      iteratorResult = synchronizerIterator.next()
    } while (!iteratorResult.done)

    return false
  }

  private updateStatus(newStatus: SynchronizationStatus) {
    if (newStatus != undefined) {
      this._status$.next(newStatus)
    } else {
      console.warn('Tried to set synchronisation status to undefined!')
    }
  }

  private getSynchronizerProperties(synchronizer: Synchronizer<ModifiableDataObject>): SynchronizerProperties {
    return this.synchronizers.get(synchronizer)
  }

  private async synchronize(synchronizer: Synchronizer<ModifiableDataObject>) {
    const synchronizerProperties = this.getSynchronizerProperties(synchronizer)
    synchronizerProperties.shouldSynchronize = false

    if (synchronizer.isCurrentlySynchronizing) {
      synchronizerProperties.repeatAfterSync = true
      return
    }
    synchronizerProperties.repeatAfterSync = false

    if (synchronizerProperties.forceSync || synchronizer.anyModifiedChanges() /* || synchronizer.anyRemoteChanges() */) {
      this.updateStatus(SynchronizationStatus.CURRENTLY_SYNCHRONIZING)
      await synchronizer.synchronize()
    }

    if (this.status != SynchronizationStatus.NOT_SYNCHRONIZED) {
      this.updateStatus(SynchronizationStatus.IS_SYNCHRONIZED)
    }

    if (synchronizerProperties.repeatAfterSync) {
      await this.synchronize(synchronizer)
    }
  }

  private async synchronizeAllOnMonthChange() {
    this.updateStatus(SynchronizationStatus.NOT_SYNCHRONIZED)
    await this.syncAllbouncer.run(async () => {
      const promises = []
      this.synchronizers.forEach((properties, synchronizer) => {
        properties.forceSync = true
        if (synchronizer.synchronizeOnMonthChange()) {
          promises.push(this.synchronize(synchronizer))
        }
      })
      await Promise.all(promises)
    })
  }

  async forceSynchronize(): Promise<void>{
    if (this.status !== SynchronizationStatus.CURRENTLY_SYNCHRONIZING){
      return this.synchronizeAll();
    }
  }

  private async synchronizeAll() {
    this.updateStatus(SynchronizationStatus.NOT_SYNCHRONIZED)
    await this.syncAllbouncer.run(async () => {
      const promises = []
      this.synchronizers.forEach((properties, synchronizer) => {
        properties.forceSync = true
        promises.push(this.synchronize(synchronizer))
      })
      await Promise.all(promises)
    })
  }

  private async synchronizeSingleSynchronizer(synchronizer: Synchronizer<ModifiableDataObject>) {
    this.updateStatus(SynchronizationStatus.NOT_SYNCHRONIZED)
    await this.syncAllbouncer.run(async () => {
      const promises = []
      this.getSynchronizerProperties(synchronizer).forceSync = true
      promises.push(this.synchronize(synchronizer))
      await Promise.all(promises)
    })
  }

  private loadLocalStateAll() {
    const synchronizerArray = Array.from(this.synchronizers.keys())

    const allPromises: Promise<void>[] = []
    for (const synchronizer of synchronizerArray) {
      allPromises.push(synchronizer.loadLocalState())
    }
    return allPromises
  }

  private initializeSynchronization(synchronizer: Synchronizer<ModifiableDataObject>, forceSync: boolean = false) {
    const synchronizerProperties = this.synchronizers.get(synchronizer)
    synchronizerProperties.shouldSynchronize = true
    synchronizerProperties.forceSync = synchronizerProperties.forceSync || forceSync
    this.updateStatus(SynchronizationStatus.NOT_SYNCHRONIZED)

    this.syncBouncer.run(() => {
      this.synchronizers.forEach((properties, storedSynchronizer) => {
        if (properties.shouldSynchronize) {
          this.synchronize(storedSynchronizer).then()
        }
      })
    }).then()
  }
}

export enum SynchronizationStatus {
  NOT_SYNCHRONIZED = 'NOT_SYNCED',
  CURRENTLY_SYNCHRONIZING = 'SYNC_PROGRESS',
  IS_SYNCHRONIZED = 'SYNC_OK'
}


