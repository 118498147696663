import {Injectable} from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class DateUtils {

  static readonly secondsOfADay = 24 * 60 * 60
  static readonly millisecondsOfADay = 1000 * DateUtils.secondsOfADay

  static get now(): Date {
    return new Date()
  }

  static get today(): Date {
    return this.now
  }

  static dateToString(date: Date): string {
    return date.getFullYear() + '-' + this.withLeadingZero(date.getMonth() + 1) + '-' + this.withLeadingZero(date.getDate())
  }

  static isAfterMonth(date: Date, month: Date): boolean {
    return DateUtils.isAfterDate(date, month) && !DateUtils.isInMonth(date, month)
  }

  static formatDate(date: Date): string {
    return (isNaN(date.getDate()) || isNaN(date.getMonth()) || isNaN(date.getFullYear()))
      ? 'n. a.'
      : `${DateUtils.withLeadingZero(date.getDate())}.${DateUtils.withLeadingZero(date.getMonth() + 1)}.${date.getFullYear()}`
  }

  static timeStamp(): string {
    const now = DateUtils.now
    return (isNaN(now.getDate())
      || isNaN(now.getMonth())
      || isNaN(now.getFullYear())
      || isNaN(now.getHours())
      || isNaN(now.getMinutes())
      || isNaN(now.getSeconds()))
      ? 'n. a.'
      : `${DateUtils.withLeadingZero(now.getDate())}.${DateUtils.withLeadingZero(now.getMonth() + 1)}.${now.getFullYear()} ${DateUtils.withLeadingZero(now.getHours())}:${DateUtils.withLeadingZero(now.getMinutes())}:${DateUtils.withLeadingZero(now.getSeconds())}`
  }

  static stringToDate(dateString: string): Date {
    const year = parseInt(dateString.substr(0, 4), 10)
    const month = parseInt(dateString.substr(5, 2), 10) - 1
    const day = parseInt(dateString.substr(8, 2), 10)

    return new Date(year, month, day)
  }

  static formatDateString(dateString: string): string {
    return this.formatDate(this.stringToDate(dateString))
  }

  static withLeadingZero(n: number): string {
    if (n < 10 && n >= 0) {
      return '0' + n.toString()
    }
    if (n < 0 && n > -9) {
      n = n * -1
      return '-0' + n.toString()
    }

    return n.toString()
  }

  static getMondayOfWeek(dayOfWeek: Date): Date {
    let daysAfterMondayOfThisWeek = dayOfWeek.getDay() - 1 // => 0=monday ... 6=sunday
    if (daysAfterMondayOfThisWeek === -1) { // sunday
      daysAfterMondayOfThisWeek = 6
    }
    return new Date(dayOfWeek.getFullYear(), dayOfWeek.getMonth(), dayOfWeek.getDate() - daysAfterMondayOfThisWeek)
  }

  static getPreviousMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth() - 1, 1)
  }

  static getNextMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth() + 1, 1)
  }

  static isBeforeMonth(date: Date, month: Date): boolean {
    return DateUtils.isBeforeDate(date, month) && !DateUtils.isInMonth(date, month)
  }

  static isBeforeOrSameMonth(date: Date, month: Date): boolean {
    return this.isBeforeMonth(date, month) || this.isInMonth(date, month)
  }

  static isAfterOrSameMonth(date: Date, month: Date): boolean {
    return this.isAfterMonth(date, month) || this.isInMonth(date, month)
  }

  static isAfterDate(date1: Date, date2: Date): boolean {
    return date1.getTime() > date2.getTime()
  }

  static isAfterOrSameDate(date1: Date, date2: Date): boolean {
    return date1.getTime() >= date2.getTime()
  }

  // TODO Write tests
  static isBetweenDate(date: Date, startDate: Date, endDate: Date): boolean {
    const valid = (startDate != null && endDate != null)
    return valid && DateUtils.isAfterDate(date, startDate) && DateUtils.isBeforeDate(date, endDate)
  }

  // TODO Write tests
  static isBetweenOrSameDate(date: Date, startDate: Date, endDate: Date): boolean {
    const valid = (startDate != null && endDate != null)
    return (valid && (DateUtils.isAfterOrSameDate(date, startDate) && DateUtils.isBeforeOrSameDate(date, endDate)))
      || (DateUtils.isSameDate(date, startDate) || DateUtils.isSameDate(date, endDate))
  }

  static isBeforeDate(date1: Date, date2: Date): boolean {
    return date1.getTime() < date2.getTime()
  }

  static isBeforeOrSameDate(date1: Date, date2: Date): boolean {
    return date1.getTime() <= date2.getTime()
  }

  static normalizeTimeValue(timeValue: TimeValue): void {
    timeValue.hours = Math.max(0, Math.min(timeValue.hours, 24))
    timeValue.minutes = Math.max(0, Math.min(timeValue.minutes, 24 * 60))

    const totalMinutes = timeValue.hours * 60 + timeValue.minutes

    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes - (hours * 60)

    timeValue.hours = hours
    timeValue.minutes = minutes
  }

  static getWeekdayName(date: Date): string {
    return WEEKDAY_NAMES[date.getDay()]
  }

  static getMonthNameAndYear(date: Date): string {
    return MONTH_NAMES[date.getMonth()] + ' ' + date.getFullYear()
  }

  static getDayAndMonth(date: Date): string {
    return DateUtils.withLeadingZero(date.getDate()) + '.' + DateUtils.withLeadingZero(date.getMonth() + 1) + '.'
  }

  static dayBelongsToMonth(date: Date, month: Date): boolean {
    return date.getMonth() === month.getMonth() && date.getFullYear() === month.getFullYear()
  }

  static isToday(date: Date): boolean {
    return this.isSameDate(date, DateUtils.today)
  }

  static isWeekendDay(date: Date): boolean {
    return date.getDay() === 6 || date.getDay() === 0
  }

  static timeValueToSeconds(timeValue: TimeValue): number {
    return timeValue.hours * 60 * 60 + timeValue.minutes * 60
  }

  static secondsToTimeValue(seconds: number): TimeValue {
    const totalMinutes = Math.ceil(Math.abs(seconds / 60))
    return {
      hours: Math.floor(totalMinutes / 60),
      minutes: totalMinutes % 60,
    }
  }

  static addDays(date: Date, numberOfDays: number): Date {
    const newDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + numberOfDays,
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    )
    date.setTime(+newDate)
    return date
  }

  static formatTimeValue(timeValue: TimeValue, zeroTimeToEmptyString: boolean = true): string {
    const formatted = `${DateUtils.withLeadingZero(timeValue.hours)}:${DateUtils.withLeadingZero(timeValue.minutes)}`
    if (formatted === '00:00' && zeroTimeToEmptyString) {
      return ''
    } else {
      return formatted
    }
  }

  static addTimeValues(a: TimeValue, b: TimeValue): TimeValue {
    let totalHours = a.hours + b.hours
    let totalminutes = a.minutes + b.minutes
    while (totalminutes / 60 > 1) {
      totalHours = totalHours + (Math.floor(totalminutes / 60))
      totalminutes = totalminutes - (Math.floor(totalminutes / 60) * 60)
    }
    return {hours: totalHours, minutes: totalminutes}
  }

  static isInWeek(date: Date, dayInWeek: Date): boolean { // unix time refers to seconds after 1.1.1970
    const mondayOfWeek = DateUtils.getMondayOfWeek(dayInWeek)
    const mondayUnixTime = mondayOfWeek.getTime()
    const nextWeekMondayUnixTime = new Date(mondayOfWeek.getFullYear(), mondayOfWeek.getMonth(), mondayOfWeek.getDate() + 7).getTime()

    const dateUnixTime = date.getTime()

    return (dateUnixTime >= mondayUnixTime) && (dateUnixTime < nextWeekMondayUnixTime)
  }

  static isInMonth(date: Date, dayInMonth: Date): boolean {
    return date.getMonth() === dayInMonth.getMonth() && date.getFullYear() === dayInMonth.getFullYear()
  }

  static isInMonthOverflowWeeks(date: Date, dayInMonth: Date): boolean {
    const firstDayOfMonth = new Date(dayInMonth.getFullYear(), dayInMonth.getMonth(), 1)
    const lastDayOfMonth = DateUtils.getLastDayOfMonth(dayInMonth)
    return !this.isInMonth(date, dayInMonth)
      && date.getFullYear() === dayInMonth.getFullYear()
      && (this.isInWeek(date, firstDayOfMonth) || this.isInWeek(date, lastDayOfMonth))
  }

  static getFirstDayOfMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), 1)
  }

  static getLastDayOfMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0)
  }

  static getFirstDayOfYear(date: Date): Date {
    return new Date(date.getFullYear(), 0, 1)
  }

  static getLastDayOfYear(date: Date): Date {
    return new Date(date.getFullYear() + 1, 0, 0)
  }

  // TODO Test
  static getLastMillisecondOfDay(date: Date): number {
    return +DateUtils.getEndOfDay(date)
  }

  static getStartOfDay(date: Date): Date {
    const day = new Date(date)
    day.setHours(0, 0, 0, 0)
    return day
  }

  static getEndOfDay(date: Date): Date {
    const day = new Date(date)
    day.setHours(23, 59, 59, 999)
    return day
  }

  static numberOfWeeksInMonth(year: number, month: number): number {
    let day = new Date(year, month, 1)
    const monthObject = new Date(year, month, 1)
    const firstMonday = DateUtils.getMondayOfWeek(day)
    const numberOfDaysToNextWeek = 7 + (firstMonday.getTime() - day.getTime()) / 86400000

    let weekCount = 1
    day = new Date(year, month, 1 + numberOfDaysToNextWeek)
    while (DateUtils.dayBelongsToMonth(day, monthObject)) {
      weekCount++
      day.setDate(day.getDate() + 7)
    }
    return weekCount

  }

  static isSameDate(date: Date, otherDate: Date): boolean {
    return (date != null && otherDate != null)
      && date.getFullYear() === otherDate.getFullYear()
      && date.getMonth() === otherDate.getMonth()
      && date.getDate() === otherDate.getDate()
  }

  static getCalendarWeek(date: Date): number {
    const currentThursday = findThursday(date)
    const currentYear = currentThursday.getFullYear()
    const thursdayInKw1 = findThursday(new Date(currentYear, 0, 4))
    return Math.floor(1.5 + (currentThursday.getTime() - thursdayInKw1.getTime()) / DateUtils.millisecondsOfADay / 7)
  }

  static dateToStringOrNull(date?: Date): string {
    return (date) ? DateUtils.dateToString(date) : null
  }
}

const findThursday = (date: Date) => { // Anm. 5
  const thursday = DateUtils.today
  thursday.setTime(date.getTime() + (3 - ((date.getDay() + 6) % 7)) * DateUtils.millisecondsOfADay)
  return thursday
}

const WEEKDAY_NAMES = {
  0: 'Sonntag',
  1: 'Montag',
  2: 'Dienstag',
  3: 'Mittwoch',
  4: 'Donnerstag',
  5: 'Freitag',
  6: 'Samstag'
}

export const MONTH_NAMES = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']

export interface TimeValue {
  hours: number
  minutes: number
}

export interface MonthYear {
  month: number
  year: number
}

export function toMonthYear(date: Date): MonthYear {
  return (date != null) ? {
    month: date.getMonth() + 1,
    year: date.getFullYear()
  } : undefined
}

export function isSameMonthYear(monthYear: MonthYear, otherMonthYear: MonthYear): boolean {
  return monthYear.year === otherMonthYear.year && monthYear.month === otherMonthYear.month
}

