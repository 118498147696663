import {DateUtils} from './date-utils';

export class CalendarCreator {
  createCalendar(selectedDate: Date): Calendar {
    const weeks: WeekEntry[] = [];

    const firstOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
    const lastOfMonth = DateUtils.getLastDayOfMonth(firstOfMonth);

    const date = DateUtils.getMondayOfWeek(firstOfMonth);
    while (DateUtils.isBeforeOrSameDate(date, lastOfMonth)) {
      const week = buildWeek(date, selectedDate);
      weeks.push(week);
      const lastDate = week.days[6].dateObject;
      date.setTime(+lastDate);
      DateUtils.addDays(date, 1);
    }

    return {
      selectedDate,
      weeks
    };
  }
}

function buildDayEntry(date: Date, selectedDate: Date): DayEntry {
  return {
    dateObject: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear(),
    weekday: date.toLocaleDateString('de-DE', {weekday: 'long', day: 'numeric', month: 'numeric'}),
    belongsToMonth: DateUtils.dayBelongsToMonth(date, selectedDate),
    belongsToWeek: DateUtils.isInWeek(date, selectedDate),
    isToday: DateUtils.isToday(date)
  } as DayEntry;
}

function buildWeek(firstDayOfWeek: Date, selectedDate: Date): WeekEntry {
  const date = firstDayOfWeek;
  const days: DayEntry[] = [];
  for (let iDay = 0; iDay < 7; iDay++) {
    days.push(buildDayEntry(date, selectedDate));
    date.setDate(firstDayOfWeek.getDate() + 1);
  }
  return {
    kw: DateUtils.getCalendarWeek(days[0].dateObject),
    days
  };
}

export interface Calendar {
  selectedDate: Date;
  weeks: WeekEntry[];
}

export interface WeekEntry {
  kw: number;
  days: DayEntry[];
}

export interface DayEntry {
  dateObject: Date;
  day: number;
  month: number;
  year: number;
  weekday: string;
  belongsToMonth: boolean;
  belongsToWeek: boolean;
  isToday: boolean;
}
