import {Component, EventEmitter, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {LocalTimeEntriesService, TimeEntry} from '../../services/repository/local-time-entries.service';
import {Async} from '../../util/async';
import {Subscription} from 'rxjs';
import {SynchronisationService, SynchronizationStatus} from '../../services/sync/synchronize-data.service';

@Component({
  selector: 'app-sync-indicator',
  templateUrl: './sync-indicator.component.html',
  styleUrls: ['./sync-indicator.component.scss']
})
export class SyncIndicatorComponent implements OnInit, OnChanges, OnDestroy {

  showIndicator = true;
  @Output() isSynchronizingEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  protected currentSyncStatus: SynchronizationStatus = SynchronizationStatus.NOT_SYNCHRONIZED;
  private localEntriesChangeSubscription: Subscription;
  private syncChangeSubscription: Subscription;

  constructor(private localTimeEntriesService: LocalTimeEntriesService,
              private synchronisationService: SynchronisationService) {
  }

  ngOnInit(): void {
    this.syncChangeSubscription = this.synchronisationService.status$.subscribe(syncStatus => {
      this.currentSyncStatus = syncStatus;
      if (!this.isSynchronizing()) {
        Async.delay(() => new Promise(() => {
          this.showIndicator = !this.isSynced();
          this.isSynchronizingEmitter.emit(this.showIndicator);
        }), 1000).then();
      } else {
        this.showIndicator = true;
        this.isSynchronizingEmitter.emit(this.showIndicator);
      }
    });
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.localEntriesChangeSubscription.unsubscribe();
  }

  ngOnDestroy(): void {
  }

  isSynchronizing(): boolean {
    return this.currentSyncStatus !== SynchronizationStatus.IS_SYNCHRONIZED;
  }

  isSynced(): boolean {
    return this.currentSyncStatus === SynchronizationStatus.IS_SYNCHRONIZED;
  }
}
