import {Component, OnDestroy, OnInit} from '@angular/core'
import {TimeNavigationService} from '../../services/time-navigation.service'
import {Subscription} from 'rxjs'
import {DateUtils} from '../../util/date-utils'
import {Calendar, CalendarCreator, DayEntry, WeekEntry} from '../../util/calendar-creator'
import {LocalAbsenceEntriesService} from '../../services/repository/local-absence-entries.service'
import {isUserSubmitted, SubmitMonthService, SubmitType} from '../../services/repository/submit-month.service'

@Component({
  selector: 'app-calendar-info',
  templateUrl: './calendar-info.component.html',
  styleUrls: ['./calendar-info.component.scss']
})
export class CalendarInfoComponent implements OnInit, OnDestroy {
  weekdays: string[] = ['M', 'D', 'M', 'D', 'F', 'S', 'S']
  calendar: InfoCalendar
  isSubmitted: boolean = undefined

  private dateSubscription: Subscription
  private localAbsenceEntriesSubscription: Subscription
  private submitMonthSubscription: Subscription

  private currentDate: Date = DateUtils.today

  constructor(private timeNavigationService: TimeNavigationService,
              private localAbsenceEntriesService: LocalAbsenceEntriesService,
              private monthSubmittedService: SubmitMonthService) {
  }

  ngOnInit(): void {
    this.dateSubscription = this.timeNavigationService.currentDay$.subscribe(date => {
      this.currentDate = date
      this.updateCalendar(date)
    })

    this.localAbsenceEntriesSubscription = this.localAbsenceEntriesService.currentMonth$.subscribe(value => {
      this.updateCalendar(this.currentDate)
    })

    this.submitMonthSubscription = this.monthSubmittedService.currentMonthSubmitted$.subscribe(submitType => {
      this.isSubmitted = isUserSubmitted(submitType)
    })
  }

  ngOnDestroy(): void {
    this.dateSubscription?.unsubscribe()
    // this.dateSubscription.unsubscribe()
    this.localAbsenceEntriesSubscription?.unsubscribe()
    this.submitMonthSubscription?.unsubscribe()
  }

  updateCalendar(selectedDate: Date): void {
    this.calendar = this.toInfoCalendar(new CalendarCreator().createCalendar(selectedDate))
  }

  navigateToWeek(week: WeekEntry): void {
    const datesInCurrentMonth = week.days.filter(
      day => DateUtils.dayBelongsToMonth(day.dateObject, this.currentDate))
    this.timeNavigationService.navigateTo(datesInCurrentMonth[0].dateObject)
  }

  isDaySubmitted(submitType: SubmitType): boolean {
    return isUserSubmitted(submitType)
  }

  private toInfoCalendar(calendar: Calendar): InfoCalendar {
    return {
      selectedDate: this.currentDate,
      weeks: calendar.weeks.map(week => {
        return {
          ...week,
          days: week.days.map(day => {
            return {
              ...day,
              containsAbsence: this.localAbsenceEntriesService.containsAbsenceEntryOnDate(day.dateObject),
              monthIsSubmitted: this.isDaySubmitted(this.monthSubmittedService.getSubmitTypeOfMonth(day.dateObject))
            } as InfoDayEntry
          })
        } as InfoWeekEntry
      })
    }
  }
}

export interface InfoCalendar extends Calendar {
  weeks: InfoWeekEntry[]
}

export interface InfoWeekEntry extends WeekEntry {
  days: InfoDayEntry[]
}

export interface InfoDayEntry extends DayEntry {
  monthIsSubmitted: boolean
  containsAbsence: boolean
}

