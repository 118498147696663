import { Injectable, OnInit } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs'
import { hasPermission, Permission, User, UserService } from './user.service'
import { TimeNavigationService } from '../time-navigation.service'
import { DateUtils } from '../../util/date-utils'
import { GraphqlCollectorService } from '../http/graphql-collector.service'
import { createVariable, GraphQLQuery } from '../../util/graphql-executor'
import { DateRange } from '../../util/date-range-picker'
import { map } from 'rxjs/operators'
import { FetchedMonthSubmittedData, FetchedMonthsubmittedDataService } from '../fetched-monthsubmitted-data.service'
import { LocalUserPropertiesService } from '../local-user-properties.service'
import {resolve} from '@angular/compiler-cli'
import {error} from 'protractor'
import { environment } from 'src/environments/environment'


@Injectable({
  providedIn: 'root'
})
export class SubmitMonthService implements OnInit {
  private repoUrl: string
  private repoUrlMe: string
  private repoUrlNoEndpoint: string
  constructor(private httpClient: HttpClient,
              private userService: UserService,
              private timeNavigationService: TimeNavigationService,
              private fetchedMonthSubmittedDataService: FetchedMonthsubmittedDataService,
              private graphqlCollectorService: GraphqlCollectorService,
              private localUserPropertiesService: LocalUserPropertiesService) {

    this.repoUrl = `${environment.serverUrl}/monthsubmitted`
    this.repoUrlMe = `${this.repoUrl}/me`
    this.repoUrlNoEndpoint = `${environment.serverUrl}`

    this.userService.me$.subscribe(me => {
      if (me != null) {
        this.currentOpenReopenedMonthsSubmitted$.subscribe(months => {
          this._allSubmitted$.next(months)
        })
        this.timeNavigationService.currentMonth$.subscribe(month => {
          this.updateMonth(month)
        })
      }
    })

    this.fetchedMonthSubmittedDataService.fetchedMonthSubmitted$.subscribe(fetchedMonthSubmitted => {
      this.evaluateFetchedMonthSubmittedData(fetchedMonthSubmitted)
    })
  }


  private _currentMonthSubmitted$ = new BehaviorSubject<SubmitType>(undefined)

  get currentMonthSubmitted$(): Observable<SubmitType> {
    return this._currentMonthSubmitted$
  }

  private _allSubmitted$ = new BehaviorSubject<Month[]>([])

  get allSubmitted$(): Observable<Month[]> {
    return this._allSubmitted$
  }

  get currentMonthSubmitted(): SubmitType {
    return this._currentMonthSubmitted$.value
  }

  get allSubmitted(): Month[] {
    return this._allSubmitted$.getValue()
  }

  get currentOpenReopenedMonthsSubmitted$(): Observable<Month[]> {
    const forceFetchMonths = [
      DateUtils.dateToString(DateUtils.getFirstDayOfMonth(this.timeNavigationService.currentMonth))
    ]
    return this.graphqlCollectorService.query(
      SubmitMonthService.currentOpenReopenedGraphsQLQueryOfUser(
        [SubmitType.Open, SubmitType.Reopened],
        this.userService.me.id,
        forceFetchMonths)
    ).pipe(
      map((result: any[]) => {
        return result.map((month) => {
            return {
              id: month.id,
              userFK: month.userFK,
              month: month.month,
              type: month.type,
              comment: month.comment || null,
              note: month.note || null,
              auditors: month.auditors || []
            } as Month
          }
        )
      }))
  }

  static isMonthSubmitCompleted(month: Month): boolean {
    return isUserSubmitted(month.type)
  }

  private static currentOpenReopenedGraphsQLQueryOfUser(submitTypes: SubmitType[], userId: number, forceFetchMonths: string[]) {
    return {
      variables: [
        createVariable('submitTypes', '[SubmitType!]', submitTypes),
        createVariable('userId', 'Int', userId),
        createVariable('forceFetchMonths', '[String!]', forceFetchMonths)
      ],
      function: 'monthsSubmittedAllWithAuditors',
      fieldBody: MONTH_TEMPLATE
    }
  }

  private static allGraphsQLQuery(submitTypes: SubmitType[], userId: number) {
    return {
      variables: [
        createVariable('submitTypes', '[SubmitType!]', submitTypes),
        createVariable('userId', 'Int', userId)
      ],
      function: 'monthsSubmittedAllWithAuditors',
      fieldBody: MONTH_TEMPLATE
    }
  }

  private static allSimpleCountGraphsQLQuery(submitTypes: SubmitType[], userId: number): GraphQLQuery {
    return {
      variables: [
        createVariable('submitTypes', '[SubmitType!]', submitTypes),
        createVariable('userId', 'Int', userId)
      ],
      function: 'monthsSubmittedAll',
      fieldBody: SIMPLE_COUNT_MONTH_TEMPLATE
    }
  }

  private static allGraphsQLQueryInRange(dateRange: DateRange, submitTypes: SubmitType[], userId: number, showMonthsSubmittedOfAll: boolean): GraphQLQuery {
    return {
      variables: [
        createVariable('submitTypes', '[SubmitType!]!', submitTypes),
        createVariable('fromDate', 'StringDate', DateUtils.dateToStringOrNull(dateRange?.from)),
        createVariable('toDate', 'StringDate', DateUtils.dateToStringOrNull(dateRange?.to)),
        createVariable('userId', 'Int', userId),
        createVariable('shouldFilter', 'Boolean', true),
        createVariable('monthsSubmittedOfAll', 'Boolean', showMonthsSubmittedOfAll),
      ],
      function: 'monthsSubmittedAllWithAuditorsInRange',
      fieldBody: MONTH_TEMPLATE
    }
  }

  ngOnInit(): void {
  }

  getSubmitMonthsOfUser(userId: number, submitType?: SubmitType): Observable<Month[]> {
    if (hasPermission(this.userService.me, Permission.ProcessOtherUsersMonthsSubmittedPermission)) {
      let params = new HttpParams()
      params = params.append('userId', userId.toString())

      if (submitType != null) {
        params = params.append('submitType', submitType)
      }

      return this.httpClient.get(this.repoUrl, {params: params}) as Observable<Month[]>
    }
  }

  addMonth(monthId: number, dateString: string): void {
    const all = this.allSubmitted

    if (!all.find(month => month.id === monthId)) {
      all.push({
        id: monthId,
        month: dateString,
        type: SubmitType.Open,
        userFK: this.userService.me.id,
        note: '',
        comment: '',
        auditors: []
      } as Month)
      this._allSubmitted$.next(all)
    }
  }

  getSubmitTypeOfMonth(date: Date): SubmitType {
    const foundMonth = this.allSubmitted.find((month) => {
      return month.month === DateUtils.dateToString(DateUtils.getFirstDayOfMonth(date))
    })

    return (foundMonth != null) ? foundMonth.type : SubmitType.Open
  }

  getSubmitTypeOfMonthOrNull(date: Date): SubmitType {
    const foundMonth = this.allSubmitted.find((month) => {
      return month.month === DateUtils.dateToString(DateUtils.getFirstDayOfMonth(date))
    })

    return foundMonth?.type
  }

  submitMonth(date: Date): Promise<boolean> {
    const newDate = new Date(date)
    newDate.setDate(1)
    const d = DateUtils.dateToString(newDate)

    const localMonth = this.allSubmitted.find(month => month.month === d)

    const submitted = SubmitType.Submitted

    return new Promise<boolean>(resolve => {
      if (localMonth != null) {
        localMonth.type = submitted
        this.httpClient.put(this.repoUrlMe + '/' + localMonth.id, localMonth).subscribe(() => {
          this._currentMonthSubmitted$.next(submitted)
          this._allSubmitted$.next(this.allSubmitted)
          resolve(true)
        }, () => {
          resolve(false)
        })
      } else {
        const monthToSubmit = {
          id: 0,
          userFK: this.userService.me.id,
          month: d,
          type: SubmitType.Open,
          comment: null,
          note: null,
          auditors: []
        } as Month
        this.allSubmitted.push(monthToSubmit)
        this._allSubmitted$.next(this.allSubmitted)

        this.httpClient.post(this.repoUrlMe, monthToSubmit, {observe: 'response'}).subscribe(month => {
          const endpoint = month.headers.get('location')
          this.httpClient.get(`${this.repoUrlNoEndpoint}/${endpoint}`, {observe: 'body'}).subscribe(returnMonth => {
            const me = returnMonth as Month
            me.type = SubmitType.Submitted
            this.httpClient.put(this.repoUrlMe + '/' + me.id, me).subscribe(() => {
              this._currentMonthSubmitted$.next(SubmitType.Submitted)
              this._allSubmitted$.next(this.allSubmitted)
            }, () => {
              resolve(false)
            })
          })
          resolve(true)
        }, () => {
          resolve(false)
        })
      }
    })
  }

  async getMonthSubmittedInRangeBySubmitTypes(
    submitTypes: SubmitType[] = null, userId: number = null, dateRange: DateRange = null
  ): Promise<Month[]> {
    const canViewAllMonthsSubmitted = this.canViewAllMonthsSubmitted()
    const showMonthsSubmittedOfAll = await this.localUserPropertiesService.getProperty(
      STORAGE_KEY_SHOW_MONTHS_SUBMITTED, !canViewAllMonthsSubmitted
    )
    return new Promise<Month[]>(resolve => {
      this.graphqlCollectorService.query(SubmitMonthService.allGraphsQLQueryInRange(
        dateRange, submitTypes, userId, canViewAllMonthsSubmitted ? showMonthsSubmittedOfAll : true
      ))
        .subscribe(resolve, error => {
          // Handle error
          console.log('Error fetching months:', error)
        })
    })
  }

  canViewAllMonthsSubmitted(): boolean {
    return hasPermission(this.userService.me, Permission.ProcessAssignmentSupervisorPermission)
      && hasPermission(this.userService.me, Permission.CheckMonthsSubmittedPermission)
  }

  getNumberOfMonthSubmittedBySubmitTypes(submitTypes: SubmitType[], userId: number = null): Promise<number> {
    return new Promise<number>(resolve => {
      this.graphqlCollectorService.query(
        SubmitMonthService.allSimpleCountGraphsQLQuery(submitTypes, userId)
      ).subscribe((months) => {
        resolve(months.length)
      }, error => {
        // Handle error
        console.log('Error fetching months:', error)
      })
    })
  }

  async deleteMonthSubmittedForUser(monthId: number): Promise<MonthModificationResult> {
    if (hasPermission(this.userService.me, Permission.ProcessOtherUsersMonthsSubmittedPermission)) {
      return new Promise<MonthModificationResult>(resolve => {
        this.graphqlCollectorService.mutation(SubmitMonthService.deleteMonthSubmittedGraphQlMutation(monthId)).subscribe(result => {
          resolve({
            success: result.success ? 2 : 1
          })
        })
      })
    } else {
      return Promise.resolve({success: 4})
    }
  }

  private static deleteMonthSubmittedGraphQlMutation(monthId: number) {
    return {
      function: 'deleteMonthSubmitted',
      variables: [
        createVariable('deleteMonthSubmittedInput', 'DeleteMonthSubmittedInput!', {monthSubmittedId: monthId})
      ],
      fieldBody: DELETE_MONTH_SUBMITTED_OUTPUT_TEMPLATE
    }
  }

  async updateMonthForUser(month: Month): Promise<MonthModificationResult> {
    return this.pushMonth(month, `${month.id}`)
  }

  async submitMonthForUser(month: Month): Promise<MonthModificationResult> {
    return this.pushMonth(month, `${month.id}/submit`)
  }

  async checkMonthForUser(month: Month) {
    return this.pushMonth(month, `${month.id}/check`)
  }

  async uncheckMonthForUser(month: Month) {
    return this.pushMonth(month, `${month.id}/uncheck`)
  }

  async unsubmitMonthForUser(month: Month, userId: number, comment?: string) {
    if (hasPermission(this.userService.me, Permission.ProcessOtherUsersMonthsSubmittedPermission)) {
      const reopenMonth = month
      reopenMonth.type = SubmitType.Reopened
      reopenMonth.comment = comment
      this.httpClient.put(`${this.repoUrlNoEndpoint}/monthsubmitted/${reopenMonth.id}`, reopenMonth).subscribe(() => {
        this.getSubmitMonthsOfUser(userId)
        this.updateMonth(this.timeNavigationService.currentMonth)
      })
    }
  }

  async verifyMonthForUser(month: Month, userId: number) {
    if (hasPermission(this.userService.me, Permission.ProcessOtherUsersMonthsSubmittedPermission)) {
      const verifiedMonth = month
      verifiedMonth.comment = ''
      verifiedMonth.type = SubmitType.Verified
      this.httpClient.put(`${this.repoUrlNoEndpoint}/monthsubmitted/${verifiedMonth.id}`, verifiedMonth).subscribe(() => {
        this.getSubmitMonthsOfUser(userId)
        this.updateMonth(this.timeNavigationService.currentMonth)
      })
    }
  }

  checkIfDateIsInCompletedMonthSubmitted(date: Date): boolean {
    const foundMonth = this.allSubmitted.find(month => DateUtils.isInMonth(DateUtils.stringToDate(month.month), date))
    if (foundMonth != null) {
      return SubmitMonthService.isMonthSubmitCompleted(foundMonth)
    }
    return false
  }

  private async pushMonth(month: Month, endpoint: string = '') {
    return new Promise<MonthModificationResult>(resolve => {
      if (hasPermission(this.userService.me, Permission.ProcessOtherUsersMonthsSubmittedPermission)) {
        const isMe = (id: number) => this.userService.me.id === id
        if (isMe(month.userFK)) {
          const foundMonth = this.allSubmitted.find(localMonth => month.userFK === localMonth.userFK && month.month === localMonth.month)

          if (foundMonth != null) {
            foundMonth.comment = month.comment
            foundMonth.note = month.note
            foundMonth.type = month.type
            foundMonth.auditors = month.auditors

            this._allSubmitted$.next(this.allSubmitted)
          }

          const isCurrentMonth = DateUtils.isInMonth(DateUtils.stringToDate(month.month), this.timeNavigationService.currentMonth)
          if (isCurrentMonth) {
            this._currentMonthSubmitted$.next(month.type)
          }
        }

        this.httpClient.put(`${this.repoUrlNoEndpoint}/monthsubmitted/${endpoint}`, month).subscribe((response) => {
            resolve({...response, success: 2})
          },
          (error) => {
            resolve({...error, success: 3})
          })
      } else {
        resolve({success: 4})
      }
    })
  }

  private evaluateFetchedMonthSubmittedData(fetchedMonthSubmittedData: FetchedMonthSubmittedData) {
    this.addMonth(fetchedMonthSubmittedData.monthId, fetchedMonthSubmittedData.month)
  }


  private updateMonth(date: Date) {
    this._currentMonthSubmitted$.next(undefined)
    const firstOfMonth = new Date(date)
    firstOfMonth.setDate(1)

    const firstOfMonthString = DateUtils.dateToString(firstOfMonth)
    this.currentOpenReopenedMonthsSubmitted$.subscribe(months => {
      const remoteMonth = months.find(submittedMonth => submittedMonth.month === firstOfMonthString)
      const submitType = (remoteMonth != null) ? remoteMonth.type : SubmitType.Open
      this._currentMonthSubmitted$.next(submitType)
      this._allSubmitted$.next(months)
    }, () => {
      this._currentMonthSubmitted$.next(SubmitType.Open)
    })
  }
}


export interface Month {
  id: number
  userFK: number
  month: string
  type: SubmitType
  comment: string
  note: string
  auditors: UserAuditorResult[]
}

export interface UserAuditorResult {
  user: User
  auditorResult: AuditorResult
  shouldCheck?: boolean
}

export enum AuditorResult {
  Accept = 'Accept',
  Decline = 'Decline',
  Nothing = 'Nothing'
}

export enum SubmitType {
  Open = 'Open',
  Reopened = 'Reopened',
  Submitted = 'Submitted',
  Checked = 'Checked',
  Verified = 'Verified'
}

export function isUserSubmitted(submitType: SubmitType): boolean {
  return submitType !== SubmitType.Open && submitType !== SubmitType.Reopened
}

export enum SubmitTypeGerman {
  Open = 'Nicht Eingereicht',
  Reopened = 'Abgelehnt',
  Submitted = 'Eingereicht',
  Checked = 'Geprüft',
  Verified = 'Angenommen'
}

export interface MonthModificationResult {
  [key: string]: any

  success: number
}

export const MONTH_TEMPLATE =
  `
    id,
    userFK,
    month,
    type,
    comment,
    note,
    auditors {
      user {
        id,
        firstName,
        lastName,
        archived
       },
      auditorResult,
      shouldCheck
    }
 `

export const SIMPLE_COUNT_MONTH_TEMPLATE = `id`

export const DELETE_MONTH_SUBMITTED_OUTPUT_TEMPLATE = `success`

export const STORAGE_KEY_SHOW_MONTHS_SUBMITTED = 'verify-show-months-submitted-of-all'
