import {Observable, Subject} from 'rxjs'
import {hasPermission, Permission, User} from '../services/repository/user.service'
import {deepCopy} from './copy'
import {ModificationResult} from '../services/repository/graphql/graphql-types'

export abstract class Synchronizer<T extends ModifiableDataObject> {
  static ID = 0
  id = Synchronizer.ID++

  protected dataArray: T[] = []
  protected gatherDataForBulkRequest = false

  private _isCurrentlySynchronizing = false

  get isCurrentlySynchronizing(): boolean {
    return this._isCurrentlySynchronizing
  }

  protected _modified$ = new Subject<boolean>()

  get modified$(): Observable<boolean> {
    return this._modified$
  }

  // Load the data from the local Storage or other sources
  public abstract loadLocalState(): Promise<void>

  public async synchronize(): Promise<void> {
    this._isCurrentlySynchronizing = true

    await this.preSynchronize()

    await new Promise<void>((resolve, _) => {
      this.pushModifiedAndUntrackedDataToRemote().then(async () => {
        this.removeInvalidData()
        await this.postSynchronize()
        // await this.saveLocalState()
        resolve()
      }, async () => {
        this.removeInvalidData()
        await this.postSynchronize()
        resolve()
      })
    })

    this._isCurrentlySynchronizing = false
    this._isCurrentlySynchronizing = false
  }

  public async pushModifiedAndUntrackedDataToRemote(): Promise<void> {
    const serverModificationPromises: Promise<void>[] = []

    const modifiedDataArray = this.dataArray.filter((data) => data.modified)
    if (this.gatherDataForBulkRequest) {
      const prePushDataArray = deepCopy(modifiedDataArray)
      const response = await this.pushDataArrayToRemote(modifiedDataArray)

      modifiedDataArray.forEach((modifiedData, index) => {
        // If the entry was modified again, while waiting for response of server, we don't want to set the modification to false
        modifiedData.modified = !this.compareModifiableFieldValues(prePushDataArray[index], modifiedData)
        getModifiableFieldProperties(modifiedData).forEach(field => {
          field.propertyValue.modified = modifiedData.modified && field.propertyValue.modified
        })
      })

      this.handleServerModificationResponse(response, modifiedDataArray, prePushDataArray)
    } else {
      modifiedDataArray
        .forEach(async (modifiedData) => {
          const prePushData = deepCopy(modifiedData)
          const response = await this.pushDataToRemote(modifiedData)

          // If the entry was modified again, while waiting for response of server, we don't want to set the modification to false
          modifiedData.modified = !this.compareModifiableFieldValues(prePushData, modifiedData)

          getModifiableFieldProperties(modifiedData).forEach(field => {
            field.propertyValue.modified = modifiedData.modified && field.propertyValue.modified
          })

          this.handleServerModificationResponseForModifiableObject(response, modifiedData, prePushData)
        }, (error) => {
          console.error('ERROR RESPONSE', error)
        })
    }

    await Promise.all(serverModificationPromises)
  }

  public anyModifiedChanges(): boolean {
    return this.dataArray.some(data => data.modified)
  }

  public abstract postSynchronize(): void

  public abstract saveLocalState(): Promise<void>

  public isAvailableFor(user: User): boolean {
    return hasPermission(user, Permission.AppUsagePermission)
  }

  public synchronizeOnMonthChange(): boolean {
    return true
  }

  protected abstract fetchRemoteData(): Promise<any[]>

  // Prepare state for synchronisation
  protected abstract mergeRemoteDataIntoLocalData(remoteData: any[]): void

  protected async pushDataArrayToRemote(dataArray: T[]): Promise<any> {
    throw new Error('pushDataArrayToRemote is not defined')
  }

  protected async pushDataToRemote(data: T): Promise<any> {
    throw new Error('pushDataToRemote is not defined')
  }

  protected abstract handleServerModificationResponseForModifiableObject(response: any, modifiedData: T, prePushData?: T): void

  // Whether a data can be deleted after the remote data is merged into the synchronizers dataArray
  protected keepAfterMergeRemoteData(data: T): boolean {
    return data.modified || data.tracked
  }

  // Whether a data can be deleted after the synchronization
  protected abstract keepAfterSynchronization(data: T): boolean

  protected abstract getDataIdentifier(data: T): number | string

  protected compareModifiableFieldValues(first: T, second: T): boolean {
    const modifiableFields = getModifiableFieldProperties(first)

    return modifiableFields.every(field => {
      const isArray = Array.isArray(field.propertyValue.value)

      const currentValue = deepCopy(field.propertyValue.value)
      const newValue = second[field.propertyName].value

      return (isArray) ? !sameArray(currentValue, newValue) : newValue === currentValue
    })
  }

  private cleanUpAfterMergeRemoteData(): void {
    this.dataArray = this.dataArray.filter(this.keepAfterMergeRemoteData)
  }

  private async preSynchronize(): Promise<void> {
    const remoteData = await this.fetchRemoteData()
    this.dataArray.forEach(data => data.tracked = false)
    await this.mergeRemoteDataIntoLocalData(remoteData)

    this.cleanUpAfterMergeRemoteData()
  }

  /**
   * @param response: Evaluate the response from the server
   * @param modifiedDataArray: The current state of the data array of the synchronizer
   * @param prePushDataArray: The state of the data array before pushing the changes to the server
   */
  private handleServerModificationResponse(response: any, modifiedDataArray: T[], prePushDataArray: T[]): void {
    if (response.result === ModificationResult.ERROR) {
      console.error('Unknown Error!')

      if (response.errors === undefined || response.errors.length === 0) {
        return
      }
    }
    modifiedDataArray.forEach((modifiedData) => {
      const prePushData = prePushDataArray.find(data => this.getDataIdentifier(data) == this.getDataIdentifier(modifiedData))
      this.handleServerModificationResponseForModifiableObject(response, modifiedData, prePushData)
    })
  }

  private removeInvalidData(): void {
    this.dataArray = this.dataArray
      .filter((data) => this.keepAfterSynchronization(data) || data.modified)
  }
}

export interface ServerResponse {
  result: ModificationResult
  errors: any[]
}

export interface ModifiableDataObject {
  modified: boolean
  tracked: boolean

  [key: string]: ModifiableField<any> | any
}

export interface ModifiableField<T> {
  value: T
  modified: boolean
}

function isModifiableField(field: any): field is ModifiableField<any> {
  return (field !== undefined) &&
    (field as ModifiableField<any>) !== null &&
    (field as ModifiableField<any>).value !== undefined &&
    (field as ModifiableField<any>).modified !== undefined
}

export function getModifiableFieldProperties<T extends ModifiableDataObject>(modifiableDataObject: T): Property<ModifiableField<any>>[] {
  const allFields = (Object.keys(modifiableDataObject) as Array<keyof T>)
  const modifiableFields =
    allFields.filter(propertyName => {
      const propertyValue = modifiableDataObject[propertyName]
      return isModifiableField(propertyValue)
    })

  return modifiableFields
    .map(propertyName => {
      return {
        propertyName: propertyName,
        propertyValue: modifiableDataObject[propertyName]
      } as Property<ModifiableField<any>>
    })
}

export function getNonModifiableFieldProperties<T extends ModifiableDataObject>(modifiableDataObject: T): Property<any>[] {

  const allFields = (Object.keys(modifiableDataObject) as Array<keyof T>)
  const nonModifiableFields =
    allFields.filter(propertyName => {
      const propertyValue = modifiableDataObject[propertyName]
      return !isModifiableField(propertyValue)
    })

  return nonModifiableFields
    .map(propertyName => {
      return {
        propertyName: propertyName,
        propertyValue: modifiableDataObject[propertyName]
      } as Property<ModifiableField<any>>
    })
}

export function modifyModifiableObject<T>(
  data: T,
  modifiableDataObject: ModifiableDataObject,
  overrideNonModifiableFields: boolean = true
): boolean {
  if (overrideNonModifiableFields) {

    getNonModifiableFieldProperties(modifiableDataObject).forEach((property) => {
      const currentValue = deepCopy(property.propertyValue)
      const newValue = data[property.propertyName]

      if (data.hasOwnProperty(property.propertyName) && currentValue != newValue) {
        modifiableDataObject[property.propertyName] = newValue
      }
    })
  }

  let anyChangesMade = false
  const modifiableFields = getModifiableFieldProperties(modifiableDataObject)

  modifiableFields
    .forEach(field => {
      const isArray = Array.isArray(field.propertyValue.value)

      const currentValue = deepCopy(field.propertyValue.value)
      const newValue = data[field.propertyName]

      const isModified = (isArray) ? !sameArray(currentValue, newValue) : newValue != currentValue

      if (isModified) {
        modifiableDataObject[field.propertyName].value = newValue

        modifiableDataObject[field.propertyName].modified = true
        anyChangesMade = true
      }
    })

  modifiableDataObject.modified = modifiableFields.some(field => field.propertyValue.modified)

  return anyChangesMade
}

function sameArray(arr: any[], arr2: any[]): boolean {
  return (arr?.length === arr2?.length) && (arr?.every(((value, index) => {
    return value == arr2[index]
  })))
}

export function mergeDataWithModifiableObject<T>(data: T, modifiableDataObject: ModifiableDataObject): boolean {
  let anyChangesMade = false

  const modifiableFields = getModifiableFieldProperties(modifiableDataObject)

  // Handle non-modified properties
  modifiableFields
    .filter(field => !field.propertyValue.modified)
    .forEach(field => {
      const newFieldValue = data[field.propertyName]

      if (newFieldValue != field.propertyValue.value) {
        modifiableDataObject[field.propertyName].value = data[field.propertyName]
        anyChangesMade = true
      }
    })

  modifiableDataObject.modified = modifiableFields.some(field => field.propertyValue.modified)

  return anyChangesMade
}

interface Property<T> {
  propertyName: string
  propertyValue: T
}


