import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {CalendarInfoComponent} from './calendar-info/calendar-info.component'
import {MatButtonModule} from '@angular/material/button'
import {MatCardModule} from '@angular/material/card'
import {MatIconModule} from '@angular/material/icon'
import {MatPaginatorModule} from '@angular/material/paginator'
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'
import {MatSelectModule} from '@angular/material/select'
import {MatSortModule} from '@angular/material/sort'
import {MatTableModule} from '@angular/material/table'
import {MatTreeModule} from '@angular/material/tree'
import {MonthNavigationComponent} from './month-navigation/month-navigation.component'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {SpeechBubbleComponent} from './speech-bubble/speech-bubble.component'
import {TooltipDialogDirective} from './tooltip-dialog/tooltip-dialog.directive'
import {ToggleButtonComponent} from './toggle-button/toggle-button.component'
import {PagerComponent} from './pager/pager.component'
import {SortableTableComponent} from './sortable-table/sortable-table.component'
import {DropDownContentComponent} from './drop-down-content/drop-down-content.component'
import {MonthPickerDialogComponent} from './month-picker-dialog/month-picker-dialog.component'
import {MonthPickerComponent} from './month-picker/month-picker.component'
import {LoadingButtonComponent} from './loading-button/loading-button.component'
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component'
import {MatDialogModule} from '@angular/material/dialog'
import {ErrorDialogComponent} from './error-dialog/error-dialog.component'
import {LoginDialogComponent} from './login-dialog/login-dialog.component'
import {FormatTimeSpanPipe, FormatTimeValuePipe, GetLabelPipe, GetMonthLabelPipe} from './shared-components.pipe'
import {SmartAddingInputComponent} from './smart-adding-input/smart-adding-input.component'
import {MatAutocompleteModule} from '@angular/material/autocomplete'
import {LoadingSpinnerComponent} from './loading-spinner/loading-spinner.component'
import {DaterangePickerComponent} from './daterange-picker/daterange-picker.component'
import {DatePickerComponent} from './datepicker/date-picker.component'
import {UserSelectionComponent} from './user-selection/user-selection.component'
import {SyncIndicatorComponent} from './sync-indicator/sync-indicator.component'
import {SelectComponent} from './select/select.component'
import {RouterModule} from '@angular/router'
import {MatInput} from '@angular/material/input'

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    FormsModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatDialogModule,
    MatSortModule,
    MatButtonModule,
    MatPaginatorModule,
    MatTreeModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    RouterModule,
    MatInput
  ],
  exports: [
    CalendarInfoComponent,
    LoadingButtonComponent,
    DropDownContentComponent,
    MonthNavigationComponent,
    MonthPickerComponent,
    MonthPickerDialogComponent,
    PagerComponent,
    SortableTableComponent,
    SpeechBubbleComponent,
    TooltipDialogDirective,
    ToggleButtonComponent,
    SyncIndicatorComponent,
    PagerComponent,
    ConfirmDialogComponent,
    ErrorDialogComponent,
    FormatTimeSpanPipe,
    FormatTimeValuePipe,
    SmartAddingInputComponent,
    LoadingSpinnerComponent,
    DaterangePickerComponent,
    DatePickerComponent,
    UserSelectionComponent,
    GetMonthLabelPipe,
    SelectComponent,
  ],
  declarations: [
    CalendarInfoComponent,
    LoadingButtonComponent,
    DropDownContentComponent,
    MonthNavigationComponent,
    MonthPickerComponent,
    MonthPickerDialogComponent,
    ConfirmDialogComponent,
    PagerComponent,
    SortableTableComponent,
    SpeechBubbleComponent,
    TooltipDialogDirective,
    ToggleButtonComponent,
    SyncIndicatorComponent,
    PagerComponent,
    ErrorDialogComponent,
    LoginDialogComponent,
    GetLabelPipe,
    FormatTimeSpanPipe,
    FormatTimeValuePipe,
    SmartAddingInputComponent,
    LoadingSpinnerComponent,
    DaterangePickerComponent,
    DatePickerComponent,
    GetMonthLabelPipe,
    UserSelectionComponent,
    SelectComponent,
  ]
})
export class SharedComponentsModule {
}
