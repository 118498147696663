import JsSha from 'jssha';

export class ShaHashing {

  static sha512(message: string): string {
    const hasher = new JsSha('SHA-512', 'TEXT', {encoding: 'UTF8'});
    hasher.update(message);
    return hasher.getHash('HEX');
  }

}
