<div class="message-box">
  <div class="message-box-title">Ein Fehler ist aufgetreten</div>
  <div class="message-box-content">
    <div class="margin-right">
      <mat-icon class="material-icons">error_outline</mat-icon>
    </div>
    <ng-container
      [ngTemplateOutlet]="defaultDialog"></ng-container>
  </div>
  <div class="message-box-actions">
    <button (click)="Ok()" class="delete-button" color="primary" mat-flat-button>Ok</button>
  </div>
</div>

<ng-template #defaultDialog>
  <div>
    <p class="message-box-info-text">Hallo,
      ein Fehler ist aufgetreten! Wir möchten uns bei dir für entstandene Unannehmlichkeiten entschuldigen.
    </p>
    <details class="error-details">
      <summary>Fehler-Details</summary>
      <div class="error-container">
        <div class="error" *ngFor="let errorMessage of data.errorMessages">
          <div class="error-timestamp">- {{errorMessage.timestamp}}</div>
          <div class="error-message">{{errorMessage.message}}</div>
        </div>
      </div>
    </details>
  </div>
</ng-template>

