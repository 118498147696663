import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, Type, ViewChild} from '@angular/core'

@Component({
  selector: 'app-drop-down-content',
  templateUrl: './drop-down-content.component.html',
  styleUrls: ['./drop-down-content.component.scss']
})
export class DropDownContentComponent<T> implements OnInit, OnChanges {

  @ViewChild('button', {read: ElementRef, static: true}) button: ElementRef

  @Input() placeholder: string
  @Input() getLabel: (value: T) => string
  @Input() dialogData: any
  @Input() dialogComponent: Type<any>
  @Input() showSelectAllButton: boolean = null

  onDialogValueChange = new EventEmitter<T>()
  labelInputData: T
  open = false
  @Output() result = new EventEmitter<{ success: boolean, object: T }>()
  private tmpLabelInputData: T

  constructor() {
  }

  toggleOptions(): void {
  }

  ngOnInit(): void {
    this.checkDialogData()
    this.onDialogValueChange.subscribe((value) => {
      window.requestAnimationFrame(() => {
        this.labelInputData = value
      })
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dialogData != null) {
      this.dialogData = changes.dialogData.currentValue
      this.checkDialogData()
    }
  }

  checkDialogData(): void {
    if (this.dialogData.onValueChange != null) {
      this.onDialogValueChange = this.dialogData.onValueChange
    } else {
      this.dialogData = {
        ...this.dialogData,
        onValueChange: this.onDialogValueChange
      }
    }

    if (this.labelInputData) {
      if (this.dialogData.preselected == null) {
        this.dialogData = {
          ...this.dialogData,
          preselected: this.labelInputData
        }
      } else {
        this.dialogData.preselected = this.labelInputData
      }
    } else {
      this.labelInputData = this.dialogData.preselected
    }
  }

  onDialogOpen(): void {
    this.open = true
    this.tmpLabelInputData = this.labelInputData
  }

  onDialogClose(result: any): void {
    this.open = false
    this.button.nativeElement.blur()

    result = (result == null) ? {
      success: false,
      object: undefined
    } : result

    if (result.success) {
      this.tmpLabelInputData = undefined
    } else {
      this.labelInputData = this.tmpLabelInputData
    }

    this.result.emit({
      success: result.success,
      object: this.labelInputData
    })
  }
}

