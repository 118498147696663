import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {customIconExists} from '../../services/custom-icons.service'

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent implements OnInit {

  @Input() toggleButtonInfos: ToggleButtonInformation[]
  @Input() currentFocus: string = null;
  @Input() disabled = false;

  // Delegates true if the left button was toggled. False otherwise
  @Output() onToggle = new EventEmitter<string>()

  toggleInFocus = ''

  ngOnInit(): void {
    if (!this.toggleButtonInfos || this.toggleButtonInfos.length == 0) {
      return
    }
    this.toggleInFocus = this.currentFocus ?? this.toggleButtonInfos[0].text;
  }

  toggle(toggleButtonText: string) {
    this.toggleInFocus = toggleButtonText
    this.emitToggleEvent()
  }

  toggleLeft() {
    this.emitToggleEvent()
  }

  toggleRight() {
    this.emitToggleEvent()
  }

  getLocallyStoredToggleIcon(toggleIcon: string): string {
    return customIconExists(toggleIcon) ? toggleIcon : ''
  }

  isFirst(toggleInfo: ToggleButtonInformation) {
    return toggleInfo == this.toggleButtonInfos[0]
  }

  isLast(toggleInfo: ToggleButtonInformation) {
    return toggleInfo == this.toggleButtonInfos[this.toggleButtonInfos.length - 1]
  }

  private emitToggleEvent() {
    this.onToggle.emit(this.toggleInFocus)
  }
}

export interface ToggleButtonInformation {
  text: string,
  icon: string
}
