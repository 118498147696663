!function (e, t) {
  if ("object" == typeof exports && "object" == typeof module) module.exports = t();else if ("function" == typeof define && define.amd) define([], t);else {
    var n = t();
    for (var r in n) ("object" == typeof exports ? exports : e)[r] = n[r];
  }
}(this, function () {
  return function (e) {
    function t(r) {
      if (n[r]) return n[r].exports;
      var o = n[r] = {
        i: r,
        l: !1,
        exports: {}
      };
      return e[r].call(o.exports, o, o.exports, t), o.l = !0, o.exports;
    }
    var n = {};
    return t.m = e, t.c = n, t.i = function (e) {
      return e;
    }, t.d = function (e, n, r) {
      t.o(e, n) || Object.defineProperty(e, n, {
        configurable: !1,
        enumerable: !0,
        get: r
      });
    }, t.n = function (e) {
      var n = e && e.__esModule ? function () {
        return e.default;
      } : function () {
        return e;
      };
      return t.d(n, "a", n), n;
    }, t.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }, t.p = "", t(t.s = 1);
  }([function (e, t, n) {
    "use strict";

    var r = this && this.__assign || Object.assign || function (e) {
      for (var t, n = 1, r = arguments.length; n < r; n++) {
        t = arguments[n];
        for (var o in t) Object.prototype.hasOwnProperty.call(t, o) && (e[o] = t[o]);
      }
      return e;
    };
    Object.defineProperty(t, "__esModule", {
      value: !0
    }), t.default = function (e) {
      return o.execute(e);
    };
    var o;
    !function (e) {
      e.execute = function (t) {
        var n;
        return null === t ? n = t : t instanceof Date ? n = new Date(t.getTime()) : Array.isArray(t) ? (n = [], t.forEach(function (e, t, r) {
          n.push(e);
        }), n = n.map(function (t) {
          return e.execute(t);
        })) : "object" == typeof t && t !== {} ? (n = r({}, t), Object.keys(n).forEach(function (t) {
          n[t] = e.execute(n[t]);
        })) : n = t, n;
      }, e.isPrimitive = function () {};
    }(o || (o = {}));
  }, function (e, t, n) {
    e.exports = n(0);
  }]);
});