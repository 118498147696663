<div class="nav-container">
  <div class="nav-title">{{ monthLabel }}</div>
  <div class="nav-elements">
    <button (click)="navigateMonth(-1)" class="small-icon-button" mat-mini-fab>
      <mat-icon title="Vorheriger Monat">
        chevron_left
      </mat-icon>
    </button>
    <button (click)="navigateMonth(0)" *ngIf="enableTodayButton" class="small-icon-button" mat-mini-fab>
      <mat-icon class="center-icon" [svgIcon]="'diamond'"
                alt="Navigation zum aktuellen Monat" title="Aktueller Monat">
      </mat-icon>
    </button>
    <button (click)="navigateMonth(1)" class="small-icon-button" mat-mini-fab>
      <mat-icon class="center-icon" title="Nächster Monat">
        chevron_right
      </mat-icon>
    </button>
  </div>
</div>
