import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {SynchronisationService, SynchronizationStatus} from '../../services/sync/synchronize-data.service';
import {Subscription} from 'rxjs';
import {LocalTimeEntriesService} from '../../services/repository/local-time-entries.service';
import {Async} from '../../util/async';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {


  @Input() loading = false;

  constructor() {}

  ngOnInit(): void {
  }

}

