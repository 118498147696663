<div *ngIf="calendar != null" class="side-by-side-layout">
  <div class="kw-annotation">
    <div class="row">
      <div class="cell left-aligned greyed-text">KW</div>
    </div>
    <div class="border-right">
      <div (click)="navigateToWeek(week)" *ngFor="let week of calendar.weeks" class="row">
        <div class="cell greyed-text">{{ week.kw }}</div>
      </div>
    </div>
  </div>
  <div class="calendar">
    <div class="row">
      <div *ngFor="let weekday of weekdays" class="cell greyed-text">{{ weekday }}</div>
    </div>
    <div class="calender-info-box">
      <div (click)="navigateToWeek(week)" *ngFor="let week of calendar.weeks"
           [class.current-week]="week.days[0].belongsToWeek"
           class="row"
      >
        <div *ngFor="let date of week.days"
             [class.absence-day-calendar-submitted]="date.containsAbsence && date.monthIsSubmitted"
             [class.absence-day-calendar]="date.containsAbsence && !date.monthIsSubmitted"
             [class.first-day-of-week]="date.dateObject.getDay() == 1"
             [class.greyed-text]="!date.belongsToMonth"
             [class.is-today]="date.isToday"
             [class.last-day-of-week]="date.dateObject.getDay() == 0"
             [class.absence-day-calendar-not-in-month]="!date.belongsToMonth && date.containsAbsence"
             class="cell"
        >
          <span class="text-middle">{{ date.day }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
