import {DateUtils} from './date-utils';


export enum DateRangePickerState {
  DAILY,
  MONTHLY,
  YEARLY
}

export class DateRangePicker {
  private range: DateRange;

  private getFromDate: (Date) => Date;
  private getToDate: (Date) => Date;
  private compareDate: (first: Date, second: Date) => number;

  constructor(from?: Date, state: DateRangePickerState = DateRangePickerState.DAILY) {
    this.range = {
      from,
      to: undefined
    };
    this.state = state;
  }


  get from(): Date {
    return this.range.from;
  }

  get to(): Date {
    return this.range.to;
  }

  set state(newState: DateRangePickerState) {
    switch (newState) {
      case DateRangePickerState.DAILY:
        this.getFromDate = this.getStartOfDay;
        this.getToDate = this.getEndOfDay;
        this.compareDate = this.compareDay;
        break;
      case DateRangePickerState.MONTHLY:
        this.getFromDate = this.getStartOfMonth;
        this.getToDate = this.getEndOfMonth;
        this.compareDate = this.compareMonth;
        break;
      case DateRangePickerState.YEARLY:
        this.getFromDate = this.getStartOfYear;
        this.getToDate = this.getEndOfYear;
        this.compareDate = this.compareYear;
        break;
    }

    if (this.range.from != undefined) {
      this.range.from = this.getFromDate(this.range.from);
      if (this.range.to != undefined) {
        this.range.to = this.getToDate(this.range.to);
      }
    }
  }

  reset() {
    this.range.from = undefined;
    this.range.to = undefined;
  }

  pickDate(date: Date) {
    if (date == undefined) {
      return;
    }

    if (this.range.from == undefined) {
      this.range.from = this.getFromDate(date);
    } else {
      const compareResult = this.compareDate(date, this.range.from);

      switch (compareResult) {
        case -1:
          this.range.from = this.getFromDate(date);
          this.range.to = undefined;
          break;
        case 0:
          this.range.from = undefined;
          this.range.to = undefined;
          break;
        case 1:
          this.range.to = this.getToDate(date);
          break;
      }
    }
  }

  setDateRange(range: DateRange): void {
    this.range = range;
  }

  private getStartOfDay(date: Date): Date {
    const lastMillisecondOfDate = this.getTimeFromStartOfDate(date);
    return new Date(lastMillisecondOfDate);
  }

  private getStartOfMonth(date: Date): Date {
    const lastMillisecondOfDate = this.getTimeFromStartOfDate(new Date(date.getFullYear(), date.getMonth()));
    return new Date(lastMillisecondOfDate);
  }

  private getStartOfYear(date: Date): Date {
    return DateUtils.getFirstDayOfYear(date);
  }

  private getEndOfDay(date: Date): Date {
    const lastMillisecondOfDate = this.getTimeFromEndOfDate(date);
    return new Date(lastMillisecondOfDate);
  }

  private getEndOfMonth(date: Date): Date {
    const lastMillisecondOfDate = this.getTimeFromEndOfDate(DateUtils.getLastDayOfMonth(date));
    return new Date(lastMillisecondOfDate);
  }

  private getEndOfYear(date: Date): Date {
    const lastMillisecondOfDate = this.getTimeFromEndOfDate(DateUtils.getLastDayOfYear(date));
    return new Date(lastMillisecondOfDate);
  }

  private compareDay(firstDate: Date, secondDate: Date): number {
    return (DateUtils.isBeforeDate(firstDate, secondDate)) ? -1
      : (DateUtils.isSameDate(firstDate, secondDate)) ? 0
        : 1;
  }

  private compareMonth(firstDate: Date, secondDate: Date): number {
    return (DateUtils.isBeforeMonth(firstDate, secondDate)) ? -1
      : (DateUtils.isInMonth(firstDate, secondDate)) ? 0
        : 1;
  }

  private compareYear(firstDate: Date, secondDate: Date): number {
    return (firstDate.getFullYear() < secondDate.getFullYear()) ? -1
      : (firstDate.getFullYear() == secondDate.getFullYear()) ? 0
        : 1;
  }

  private getTimeFromStartOfDate(date: Date): number {
    const newDate = new Date(date);
    newDate.setMilliseconds(0);
    newDate.setSeconds(0);
    newDate.setMinutes(0);
    newDate.setHours(0);

    return +newDate;
  }

  private getTimeFromEndOfDate(date: Date): number {
    const newDate = new Date(date);
    newDate.setMilliseconds(999);
    newDate.setSeconds(59);
    newDate.setMinutes(59);
    newDate.setHours(23);

    return +newDate;
  }
}


export interface DateRange {
  from: Date,
  to: Date
}
