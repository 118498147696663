import {Component, EventEmitter, Input, Output} from '@angular/core'

@Component({
  selector: 'app-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss']
})
export class LoadingButtonComponent {

  @Input() text: string
  @Input() icon: string
  @Input() isLoading: boolean

  @Output() onClick = new EventEmitter<void>()
  @Input() disabled: boolean = null

  constructor() {
  }

  click(): void {
    if (!this.isLoading) {
      this.onClick.emit()
    }
  }

}
