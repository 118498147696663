export class Bouncer {
  private timeBasedResyncHandle: number;

  constructor(private bounceTime: number) {
  }

  async run(runnable: () => void): Promise<void> {
    this.reset();

    return new Promise<void>(resolve => {
      if (this.bounceTime > 0) {
        this.timeBasedResyncHandle = window.setTimeout(() => {
          runnable();
          resolve();
        }, this.bounceTime);
      } else {
        runnable();
        resolve();
      }
    });
  }

  reset(): void {
    if (this.timeBasedResyncHandle != null) {
      window.clearTimeout(this.timeBasedResyncHandle);
      this.timeBasedResyncHandle = null;
    }
  }
}
